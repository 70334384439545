import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Wrapper from './layout/Wrapper/Wrapper';
import AsideRoutes from './layout/Aside/AsideRoutes'


function App() {
  return (
    <Router>
      <AsideRoutes />
      <Wrapper />
    </Router>
  );
}

export default App;
