import React from "react";
// import LoginBackground from '../../assets/img/Login.svg';
import LoginBackground from '../../assets/img/Login.png';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid, Typography, OutlinedInput, FormGroup , Button, FormControlLabel, FormControl } from '@mui/material';
import CustomizedCheckbox from "../../components/icons/ChckedBox";
import useMediaQuery from '@mui/material/useMediaQuery';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

const BackgroundGrid = styled(Grid)(({theme}) => ({
    width:'100%', 
    height:'100vh', 
    backgroundImage: "linear-gradient( rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6)), url(/img/Login.png)", 
    backgroundRepeat: 'no-repeat', 
    backgroundSize: '100% 100%',
}))

const BackgroundTitle = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans', 
    fontSize: '30px',
    color: 'white',
    textAlign: 'center'
}))

const BackgroundText = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans', 
    fontSize: '16px',
    color: 'white',
    textAlign: 'center'
}))

const ContentGrid = styled(Grid)(({theme}) => ({
}))

const LogoGrid = styled(Grid)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
}))

const ContentTitle = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans', 
    fontSize: '30px',
}))

const ContentText = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans', 
    fontSize: '14px',
    fontWeight: '500'
}))

const RadioGrid = styled(Grid)(({theme}) => ({
    display: 'flex',
    justifyContent: 'left',
}))

const CustomButton = styled(Button)(({theme}) => ({
    width:'100%', 
    height: '43px',
    textTransform: 'none', 
    backgroundColor: "#6F6AF8", 
    fontFamily: 'Plus Jakarta Sans',
    '&:hover': {
        backgroundColor: "#6F6AF8 !important"
    }
}))

const ResetPassword = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    return (
        <Box>
            <Grid container>
                {matches? (
                <>
                <BackgroundGrid item xs={5}>
                    <div style={{marginTop: '80vh'}}>
                        <BackgroundTitle>Boost your business with AI</BackgroundTitle>
                        <BackgroundText>Uncover your business's level of outcome, and <br/>areas for opportunity.</BackgroundText>
                    </div>
                </BackgroundGrid>
                </>
                ):(
                <>
                </>)}
                <Grid item xs={matches? 7 : 12}>
                    <ContentGrid container spacing={2}>
                        <LogoGrid item xs={12} sx={{margin: matches?'100px 30% 10px 30%':'20px 10px 10px 10px'}}>
                            <img src="/img/Logo.png" width={'60%'}></img>
                        </LogoGrid>
                        {matches? (
                        <>
                        </>
                        ):(
                        <>
                            <hr width='100%'/>
                        </>)}
                        <Grid item xs={12} sx={{ margin: matches?'50px 30% 10px 30%':'20px 20px 0px 20px' }}>
                            <ContentTitle><strong>Reset Your Password</strong></ContentTitle>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: matches?'0px 30% 10px 30%':'0px 20px 10px 20px', paddingTop:'0px !important' }}>
                            <ContentText sx={{color: '#84818A', fontSize: '14px'}}>Secure Your Account By Selecting a New Password</ContentText>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: matches?'0px 30% 0px 30%':'0px 20px 0px 20px' }}>
                            <ContentText>New Password</ContentText>
                            <FormControl sx={{ width: '100%', marginTop: '10px' }} size="small">
                                <OutlinedInput placeholder="Enter Password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <RadioGrid item xs={12} sx={{ margin: matches?'0px 30% 0px 30%':'0px 20px 0px 20px' }}>
                            <CustomizedCheckbox  /><ContentText sx={{color: '#84818A', fontSize: '14px'}}>Be minimum eight characters</ContentText>
                        </RadioGrid>
                        <RadioGrid item xs={12} sx={{ margin: matches?'0px 30% 0px 30%':'0px 20px 0px 20px' }}>
                            <CustomizedCheckbox /><ContentText sx={{color: '#84818A', fontSize: '14px'}}>Have at least one number</ContentText>
                        </RadioGrid>
                        <RadioGrid item xs={12} sx={{ margin: matches?'0px 30% 10px 30%':'0px 20px 10px 20px' }}>
                            <CustomizedCheckbox  /><ContentText sx={{color: '#84818A', fontSize: '14px'}}>Have at least one special character</ContentText>
                        </RadioGrid>
                        <Grid item xs={12} sx={{ margin: matches?'0px 30% 10px 30%':'0px 20px 10px 20px' }}>
                            <ContentText>Confirm Password</ContentText>
                            <FormControl sx={{ width: '100%', marginTop: '10px' }} size="small">
                                <OutlinedInput placeholder="Confirm New Password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: matches?'0px 30% 10px 30%':'0px 20px 10px 20px' }}>
                            <CustomButton variant="contained" sx={{width:'100%', textTransform: 'none'}}>
                                Reset Password
                            </CustomButton>
                        </Grid>
                    </ContentGrid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ResetPassword;