import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    Grid,
    IconButton,
    Tooltip,
    FormControlLabel,
    Switch,
    Checkbox ,
} from "@mui/material";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import TrashIcon from '../assets/img/Trash.svg';
import EditIcon from '../assets/img/Edit.svg';
import useMediaQuery from '@mui/material/useMediaQuery';

const Title = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '24px',
}))

const Content = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    color: '#84818A',
}))

const CustomButton = styled(Button)(({theme}) => ({
    color: 'white',
    fontSize: '16px',
    height: '42px',
    textTransform: 'none', 
    backgroundColor: "#6F6AF8", 
    fontFamily: 'Plus Jakarta Sans',
    '&:hover': {
        backgroundColor: "#6F6AF8 !important"
    }
}))

const EditButton = styled(Button)(({theme}) => ({
  width:'50%', 
  height: '43px',
  textTransform: 'none', 
  border: '1px solid', 
  borderColor: '#D6DDEB',
  fontFamily: 'Plus Jakarta Sans', 
  color: 'black',
}))

const PasswordButton = styled(Button)(({theme}) => ({
  width:'100%', 
  height: '42px',
  textTransform: 'none', 
  backgroundColor: "#6F6AF8", 
  fontFamily: 'Plus Jakarta Sans',
  '&:hover': {
      backgroundColor: "#6F6AF8 !important"
  }
}))

const BoxContent = styled(Typography)(({theme}) => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '16px',
  fontWeight: 700,
}))

function createData(no, name, email, companyName, lastAccess, role) {
    return {
        no,
        name,
        email,
        companyName,
        lastAccess,
        role,
    };
}
  
const rows = [
    createData(1, 'Mbah Jacob', 'ckctm12@gmail.com', 'Umbrella Corporation', '03 May 2023 4:32 PM', 'Editor', ''),
    createData(2, 'Hellena John', 'nvt.isst.nute@gmail.com', 'Initech', '22 Jan 2023 4:32 PM', 'Owner', ''),
    createData(3, 'Hellen Jummy', 'manhhachkt08@gmail.com', 'Gekko & Co', '05 Jun 2023 4:32 PM', 'Admin', ''),
    createData(4, 'Isaac Oluwatemilorun', 'binhan628@gmail.com', 'Genco Olive Oil Company', '17 Oct 2023 4:32 PM', 'Editor', ''),
    createData(5, 'Edwin Adenike', 'thuhang.nute@gmail.com', 'Soylent Corp', '01 May 2023 4:32 PM', 'Viewer', ''),
];
   
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
  
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    // {
    //   id: 'no',
    //   numeric: true,
    //   disablePadding: false,
    //   label: '#',
    // },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Full Name',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'companyName',
      numeric: false,
      disablePadding: false,
      label: 'Company Name',
    },
    {
      id: 'lastAccess',
      numeric: false,
      disablePadding: false,
      label: 'Last Access',
    },
    {
      id: 'role',
      numeric: false,
      disablePadding: false,
      label: 'Role',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: 'Action',
    },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{fontFamily: 'Plus Jakarta Sans', backgroundColor: '#F8F8F8', border: '1px solid #F2F2F2', borderRadius: '4px'}}>
      <TableRow>
          {/* <TableCell padding="checkbox" sx={{ color: '#737373' }}>
              <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                  'aria-label': 'select all desserts',
              }}
              />
          </TableCell> */}
          {headCells.map((headCell) => (
          <TableCell
              key={headCell.id}
              align={'left'}
              sortDirection={orderBy === headCell.id ? order : false}
              sx={{ color: '#737373', padding: '5px 20px 5px 20px' }}
          >
              <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
              >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                  ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
  


const UserManagement = () => {
    const matches = useMediaQuery('(min-width:600px)');

    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(7);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelected = rows.map((n) => n.no);
        setSelected(newSelected);
        return;
        }
        setSelected([]);
    };

    const handleClick = (event, no) => {
        const selectedIndex = selected.indexOf(no);
        let newSelected = [];

        if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, no);
        } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
        );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (no) => selected.indexOf(no) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
        stableSort(rows, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        ),
        [order, orderBy, page, rowsPerPage],
    );

    

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container padding={matches?'20px 40px 20px 40px':'20px 20px 20px 20px'} spacing={3}>
                        <Grid item xs={matches?6:12} display='flex' justifyContent='left'>
                            <Title>
                                <b>User Management  </b><span style={{color: '#84818A'}}>(05)</span>
                            </Title>
                        </Grid>
                        <Grid item xs={matches?6:12} display='flex' justifyContent={matches?'right':'left'}>
                            <CustomButton sx={{width:matches?'200px':'100%'}}>{'+ Add New Member'}</CustomButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} padding={matches?'20px 40px 20px 40px':'20px 20px 20px 20px'}>
                  {matches?(
                      <TableContainer>
                          <Table
                              sx={{ minWidth: 750 }}
                              aria-labelledby="tableTitle"
                              size={dense ? 'small' : 'medium'}
                          >
                              <EnhancedTableHead
                              numSelected={selected.length}
                              order={order}
                              orderBy={orderBy}
                              onSelectAllClick={handleSelectAllClick}
                              onRequestSort={handleRequestSort}
                              rowCount={rows.length}
                              />
                              <TableBody>
                                  {visibleRows.map((row, index) => {
                                      const isItemSelected = isSelected(row.no);
                                      const labelId = `enhanced-table-checkbox-${index}`;

                                      return (
                                      <TableRow
                                          hover
                                          onClick={(event) => handleClick(event, row.no)}
                                          role="checkbox"
                                          aria-checked={isItemSelected}
                                          tabIndex={-1}
                                          key={row.no}
                                          selected={isItemSelected}
                                          sx={{ cursor: 'pointer', backgroundColor: index%2==1?'#F8F8F8':'' }}
                                      >
                                          <TableCell align="left" sx={{ fontFamily: 'Plus Jakarta Sans', padding: '20px 20px 20px 20px', display: 'flex', alignItems: 'center' }}>
                                              <b>{row.name}</b>
                                          </TableCell>
                                          <TableCell align="left" sx={{ fontFamily: 'Plus Jakarta Sans', padding: '10px 20px 10px 20px' }}><b>{row.email}</b></TableCell>
                                          <TableCell align="left" sx={{ fontFamily: 'Plus Jakarta Sans', padding: '10px 20px 10px 20px' }}><b>{row.companyName}</b></TableCell>
                                          <TableCell align="left" sx={{ fontFamily: 'Plus Jakarta Sans', padding: '10px 20px 10px 20px' }}><b>{row.lastAccess}</b></TableCell>
                                          <TableCell align="left" sx={{ fontFamily: 'Plus Jakarta Sans', padding: '10px 20px 10px 20px' }}>
                                              <Box sx={{width: '50px', backgroundColor: row.status?'#E6F9EE':'#FFF0E0', 
                                                      padding: '4px 8px 4px 8px', borderRadius: '16px', color: 'white',
                                                      backgroundColor: row.role=='Editor'? '#1676F3': row.role=='Owner'? '#192434': row.role=='Admin'? '#15C15D': '#FF991E' ,
                                                      display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                  <b>{row.role}</b>
                                              </Box>
                                          </TableCell>
                                          <TableCell align="left" sx={{ fontFamily: 'Plus Jakarta Sans', padding: '10px 20px 10px 20px' }}>
                                              <Button size="small" variant="outlined" sx={{border: '1px solid #f1dede', padding: '4px 10px 0 10px', height: '30px', textTransform: 'none', marginRight:'10px'}}>
                                                  <img src={EditIcon} alt="EditIcon" height='20px' width='20px' style={{marginRight:'4px'}} />
                                                  {'Manage Permission'}
                                              </Button>
                                              <Button size="small" variant="outlined" sx={{border: '1px solid #f1dede', height: '30px', marginRight: '10px'}}>
                                                  <img src={TrashIcon} alt="TrashIcon" height='20px' width='20px'/>
                                              </Button>
                                          </TableCell>
                                      </TableRow>
                                      );
                                  })}
                              {emptyRows > 0 && (
                                  <TableRow
                                  style={{
                                      height: (dense ? 33 : 53) * emptyRows,
                                  }}
                                  >
                                  <TableCell colSpan={6} />
                                  </TableRow>
                              )}
                              </TableBody>
                          </Table>
                      </TableContainer>
                    ):(
                      <>
                        {rows.map((row, index) => {
                          const isItemSelected = isSelected(row.no);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          return (
                            <Grid item xs={12}>
                                <Grid container sx={{border: '1px solid #E2E2E2', borderRadius: '8px', padding: '10px'}}>
                                    <Grid item xs={7} sx={{display: 'flex', justifyContent: 'left', alignItems: 'center', marginBottom: '10px'}}>
                                        <BoxContent>{row.name}</BoxContent>
                                    </Grid>
                                    <Grid item xs={5} sx={{display: 'flex', justifyContent: 'right', alignItems: 'center', marginBottom: '10px'}}>
                                      <BoxContent sx={{width: '50px', backgroundColor: row.status?'#E6F9EE':'#FFF0E0', fontWeight: 500, fontSize: '14px',
                                              padding: '4px 8px 4px 8px', borderRadius: '16px', color: 'white',
                                              backgroundColor: row.role=='Editor'? '#1676F3': row.role=='Owner'? '#192434': row.role=='Admin'? '#15C15D': '#FF991E' ,
                                              display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                          {row.role}
                                      </BoxContent>
                                    </Grid>
                                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'left', alignItems: 'center', marginBottom: '10px'}}>
                                        <BoxContent sx={{color: '#84818A'}}>{'Email'}</BoxContent>
                                    </Grid>
                                    <Grid item xs={6} sx={{display: 'flex', justifyContent: 'right', alignItems: 'center', marginBottom: '10px'}}>
                                        <BoxContent>{row.email}</BoxContent>
                                    </Grid>
                                </Grid>
                            </Grid>
                          )
                        })}
                      </>
                    )}
                </Grid>                
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'right', marginTop: matches?'10%':'10px'}}>
                    <Grid container padding={matches?'20px 40px 20px 40px':'20px'}>
                        {matches?(<>
                        <Grid item xs={9.6}>
                        </Grid>
                        </>):(<></>)
                        }
                        <Grid item xs={matches?1:6} sx={{marginRight: matches?'30px':'0', display:'flex', justifyContent:'left'}}>
                            <EditButton sx={{width: matches?'100%':'95%'}}><b>Discard</b></EditButton>
                        </Grid>
                        <Grid item xs={matches?1:6} sx={{display:'flex', justifyContent:'right'}}>
                            <PasswordButton variant="contained" sx={{width: matches?'100%':'95%'}}>Save</PasswordButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

export default UserManagement;

