import React from "react";
// import LoginBackground from '../../assets/img/Login.svg';
// import ForgotPassword from '../../assets/img/ForgotPassword.png';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid, Typography, FormControl, OutlinedInput, Checkbox, Link, Button } from '@mui/material';
import GmailIcon from '../../assets/img/Gmail.svg';
import OutlookIcon from '../../assets/img/Outlook.svg';
import useMediaQuery from '@mui/material/useMediaQuery';

const BackgroundGrid = styled(Grid)(({theme}) => ({
    width:'100%', 
    height:'100vh', 
    // background: 'linear-gradient(to bottom, transparent 0%,transparent 50%,red 50%,red 100%)',
    backgroundImage: "linear-gradient( rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6)), url(/img/CheckEmail.png)", 
    backgroundRepeat: 'no-repeat', 
    backgroundSize: '100% 100%',
}))

const BackgroundTitle = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '30px',
    color: 'white',
    textAlign: 'center'
}))

const BackgroundText = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    color: 'white',
    textAlign: 'center'
}))

const ContentGrid = styled(Grid)(({theme}) => ({
}))

const LogoGrid = styled(Grid)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
}))
const EmailBoxGrid = styled(Grid)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
}))

const ContentTitle = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    textAlign: 'center',
    fontSize: '30px',
}))

const ContentText = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    color: '#84818A',
    textAlign: 'center',
    fontSize: '14px',
}))

const CustomButton = styled(Button)(({theme}) => ({
    width:'100%', 
    textTransform: 'none', 
    color: 'black',
    border: '1px solid',
    borderColor: '#D6DDEB',
}))


const CheckEmail = () => {
    const matches = useMediaQuery('(min-width:600px)');
    return (
        <Box>
            <Grid container>
                {matches? (
                <>
                <BackgroundGrid item xs={5}>
                    <div style={{marginTop: '80vh'}}>
                        <BackgroundTitle>Boost your business with AI</BackgroundTitle>
                        <BackgroundText>Uncover your business's level of outcome, and <br/>areas for opportunity.</BackgroundText>
                    </div>
                </BackgroundGrid>
                </>
                ):(
                <>
                </>)}
                <Grid item xs={matches? 7 : 12}>
                    <ContentGrid container spacing={2}>
                        <LogoGrid item xs={12} sx={{margin: matches?'100px 30% 10px 30%':'20px 10px 10px 10px'}}>
                            <img src="/img/Logo.png" alt="logo" width={matches?'250px':'200px'} />
                        </LogoGrid>
                        {matches? (
                        <>
                        </>
                        ):(
                        <>
                            <hr width='100%'/>
                        </>)}
                        <EmailBoxGrid item xs={12} sx={{marginTop: matches?'100px':'80px'}}>
                            <img src="/img/EmailBox.png" alt="EmailBox" width={matches?'200px':'150px'}></img>
                        </EmailBoxGrid>
                        <Grid item xs={12} sx={{ margin: matches?'0px 30% 10px 30%':'0px 20px 10px 20px' }}>
                            <ContentTitle><strong>Check your email</strong></ContentTitle>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: matches?'0px 20% 10px 20%':'0px 20px 10px 20px', paddingTop:'0px !important' }}>
                            <ContentText>We’ve sent a verification link to your email <strong style={{color: 'black'}}>example@email.com</strong>{matches?(<br/>):(<>{' '}</>)}Click the link to verify and continue using this platform</ContentText>
                        </Grid>
                        <Grid item xs={matches? 6 : 12} sx={{display: 'flex', justifyContent: matches?'right':'center'}}>
                            <CustomButton sx={{width: matches?'250px':'90%'}}>
                                <img src={GmailIcon} style={{marginRight: '10px'}}/>
                                <strong>Open Gmail</strong>
                            </CustomButton>
                        </Grid>
                        <Grid item xs={matches? 6 : 12} sx={{display: 'flex', justifyContent: matches?'left':'center'}}>
                            <CustomButton sx={{width: matches?'250px':'90%'}}>
                                <img src={OutlookIcon} style={{marginRight: '10px'}}/>
                                <strong>Open Outlook</strong>
                            </CustomButton>
                        </Grid>
                    </ContentGrid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CheckEmail;