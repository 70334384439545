import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import { Button, Divider, List, ListItem, ListItemText } from "@mui/material";
import DatabaseTypeIcon from '../assets/img/DatabaseType.svg';
import ServerNameIcon from '../assets/img/ServerName.svg';
import AuthenticationMethodIcon from '../assets/img/AuthenticationMethod.svg';
import UserNameIcon from '../assets/img/UserName.svg';
import PasswordIcon from '../assets/img/Password.svg';
import DisconnectIcon from '../assets/img/Disconnect.svg';
import useMediaQuery from '@mui/material/useMediaQuery';

const ContentTitle = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    marginLeft: '10px',
    color: '#84818A',
}))

const ContentText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    marginLeft: '10px',
}))

const Title = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '30px',
}))

const Content = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    color: '#84818A',
}))

const DisconnectButton = styled(Button)(({theme}) => ({
    width:'250px', 
    height: '43px',
    textTransform: 'none', 
    // border: '1px solid',     
    backgroundColor: "#F02532", 
    fontFamily: 'Plus Jakarta Sans', 
    color: 'white',
    '&:hover': {        
    backgroundColor: "#F02532", 
    }
}))

const DatabaseSettings = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();

    return (
        <>
            <Grid container sx={{ minHeight: '100%', flexDirection: 'column' }}>
                <Grid container>
                    <Grid item xs={12} sx={{margin: '30px 0 0 30px'}}>
                        <Title><b>Database Settings</b></Title>
                    </Grid>
                    <Grid item xs={12} sx={{margin: '0 20px 0 30px'}}>
                        <Content>To securely connect to your database and use our chatbot data assistant, please provide your credentials for authentication.</Content>
                    </Grid>
                </Grid>

                <Grid item xs={12} sx={{ flexDirection: 'column', display: 'flex'}}>
                    <List sx={{}}>
                        <ListItem>
                            <ListItemText align="left" 
                                sx={{
                                    borderRadius: '10px 10px 0px 0px',
                                    border: '1px solid',
                                    borderColor: '#E6E6E6',
                                    // padding: '10px',
                                    color: 'black',
                                    fontFamily: 'Plus Jakarta Sans',
                                    textAlign: 'left', 
                                    margin: '15px 15px 15px 15px '
                                }}
                            >
                                <Grid container>
                                    <Grid item xs={12} style={{backgroundColor: 'rgb(199 198 237)', borderRadius: '10px 10px 0 0', padding: '10px 20px 10px 20px'}}>
                                        <Typography sx={{fontFamily: 'Plus Jakarta Sans', color: '#6F6AF8', fontSize: '20px'}}><b>Database Connected</b></Typography>
                                    </Grid>
                                    <Grid item xs={matches?3:12} sx={{display: 'flex', justifyContent: 'left', padding: '10px 20px 10px 20px'}}>
                                        <img src={DatabaseTypeIcon} alt="DatabaseTypeIcon" />
                                        <div style={{display: 'flex', flexDirection: 'column', }}>
                                            <ContentTitle>Database Type</ContentTitle>
                                            <ContentText><b>Microsoft SQL Server</b></ContentText>
                                        </div>
                                    </Grid>
                                    <Grid item xs={matches?3:12} sx={{display: 'flex', justifyContent: 'left', padding: '10px 20px 10px 20px'}}>
                                        <img src={ServerNameIcon} alt="ServerNameIcon" />
                                        <div style={{display: 'flex', flexDirection: 'column', }}>
                                            <ContentTitle>Server Name</ContentTitle>
                                            <ContentText><b>ACX_JW Data Point</b></ContentText>
                                        </div>
                                    </Grid>
                                    <Grid item xs={matches?3:12} sx={{display: 'flex', justifyContent: 'left', padding: '10px 20px 10px 20px'}}>
                                        <img src={AuthenticationMethodIcon} alt="AuthenticationMethodIcon" />
                                        <div style={{display: 'flex', flexDirection: 'column', }}>
                                            <ContentTitle>Authentication Method</ContentTitle>
                                            <ContentText><b>SQL Server Authentication</b></ContentText>
                                        </div>
                                    </Grid>
                                    <Grid item xs={matches?3:12} sx={{display: 'flex', justifyContent: 'left', padding: '10px 20px 10px 20px'}}>
                                    </Grid>
                                    <Grid item xs={12} sx={{padding: '0 10px 0 10px'}}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={matches?3:12} sx={{display: 'flex', justifyContent: 'left', padding: '10px 20px 10px 20px'}}>
                                        <img src={UserNameIcon} alt="UserNameIcon" width='34px' />
                                        <div style={{display: 'flex', flexDirection: 'column', }}>
                                            <ContentTitle>Username</ContentTitle>
                                            <ContentText><b>Elie Amar</b></ContentText>
                                        </div>
                                    </Grid>
                                    <Grid item xs={matches?3:12} sx={{display: 'flex', justifyContent: 'left', padding: '10px 20px 10px 20px'}}>
                                        <img src={PasswordIcon} alt="PasswordIcon" width='34px'/>
                                        <div style={{display: 'flex', flexDirection: 'column', }}>
                                            <ContentTitle>Password</ContentTitle>
                                            <ContentText>**********</ContentText>
                                            
                                        </div>
                                    </Grid>
                                    <Grid item xs={matches?3:12} sx={{display: 'flex', justifyContent: 'left', padding: '10px 20px 10px 20px'}}>
                                    </Grid>
                                    <Grid item xs={matches?3:12} sx={{display: 'flex', justifyContent: matches?'right':'center', padding: '10px 20px 10px 20px'}}>
                                        <DisconnectButton variant="contained">
                                            <img src={DisconnectIcon} alt="DisconnectIcon" style={{marginRight: '10px'}}/>
                                            <strong>Disconnect</strong>
                                        </DisconnectButton>
                                    </Grid>
                                </Grid>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </>
    )
};

export default DatabaseSettings;

