import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import { ListItem } from '@mui/material';
// import ListItemButton from '@mui/material/ListItemButton';
import AssistantIcon from '../../assets/img/Assistant.svg';
import UploadIcon from '../../assets/img/Upload.svg';
import DatabaseIcon from '../../assets/img/Database.svg';
import SettingsIcon from '../../assets/img/Settings.svg';
import LogoutIcon from '../../assets/img/Logout.svg';
import useMediaQuery from '@mui/material/useMediaQuery';


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
        width: '70px',
        overflow: 'unset',
        backgroundColor: '#6F6AF8'
    },
  }),
);

const ListItemButton = styled(ListItem)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
}))


// export const AsideHead = ({ children }) => {
// 	return <div className='aside-head'>{children}</div>;
// };

// export const AsideBody = ({ children }) => {
// 	return <div className='aside-body'>{children}</div>;
// };

// export const AsideFoot = ({ children }) => {
// 	return <div className='aside-foot'>{children}</div>;
// };


const Aside = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    // const theme = useTheme();
    // const [open, setOpen] = React.useState(false);
    // const handleDrawerClose = () => {
    //   setOpen(false);
    // };

    return (
        <>
            {matches?(<>
                <Drawer variant="permanent">
                    <DrawerHeader>
                        <ListItemButton>
                            <MenuIcon sx={{color:'white', width: '50px'}}/>
                        </ListItemButton>
                    </DrawerHeader>
                    
                    <List sx={{marginTop: '20px', display: 'flex', flexDirection: 'column', height: '100%'}}>
                        <ListItemButton sx={{flexGrow: '0'}} onClick={() => navigate('/')}>
                            <img src={AssistantIcon} alt='AssistantIcon'/>
                        </ListItemButton>
                        <ListItemButton sx={{flexGrow: '0'}} onClick={() => navigate('/upload')}>
                            <img src={UploadIcon}  alt='UploadIcon'/>
                        </ListItemButton>
                        <ListItemButton sx={{flexGrow: '0'}} onClick={() => navigate('/database')}>
                            <img src={DatabaseIcon}  alt='DatabaseIcon'/>
                        </ListItemButton>
                        <ListItemButton sx={{flexGrow: '0'}} onClick={() => navigate('/settings')}>
                            <img src={SettingsIcon}  alt='SettingsIcon'/>
                        </ListItemButton>
                        <ListItemButton sx={{flexGrow: '0', marginTop: 'auto', alignItems: 'end'}} onClick={() => navigate('/login')}>
                            <img src={LogoutIcon}  alt='LogoutIcon'/>
                        </ListItemButton>
                        
                    </List>
                </Drawer>
            </>
            ):(
            <></>
            )}
        </>
    )
}

export default Aside;