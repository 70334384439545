import React from "react";
import { mainMenu } from "../menu";
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import ForgotPassword from "../pages/auth/ForgotPassword";
import CheckEmail from "../pages/auth/CheckEmail";
import ResetPassword from "../pages/auth/ResetPassword";

import MyAssistant from "../pages/MyAssistant";
import Database from '../pages/Database';
import UploadFiles from "../pages/UploadFiles";
import Settings from "../pages/Settings";

const contents = [
    { path: mainMenu.login.path, element: <Login /> },
    { path: mainMenu.signUp.path, element: <Signup /> },
    { path: mainMenu.forgotPassword.path, element: <ForgotPassword /> },
    { path: mainMenu.checkEmail.path, element: <CheckEmail /> },
    { path: mainMenu.resetPassword.path, element: <ResetPassword /> },
    { path: mainMenu.myAssistant.path, element: <MyAssistant /> },
    { path: mainMenu.database.path, element: <Database /> },
    { path: mainMenu.uploadFiles.path, element: <UploadFiles /> },
    { path: mainMenu.settings.path, element: <Settings /> },
]

export default contents;