import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import { Button, Divider, List, ListItem, ListItemText, OutlinedInput, FormControl, InputAdornment } from "@mui/material";
import Container from '@mui/material/Container';
import IncIcon from '../assets/img/IncIcon.svg';
import DBConnectionIcon from '../assets/img/DBConnectionIcon.svg';
import DatabaseIcon from '../assets/img/DatabaseIcon.svg';
import AvatarIcon from '../assets/img/Avatar.svg';
// import '../styles/Profile.css';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import PhoneInput from "react-phone-input-2";
import useMediaQuery from '@mui/material/useMediaQuery';

const Title = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '26px',
}))

const Content = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    color: '#84818A',
}))

const EditButton = styled(Button)(({theme}) => ({
    width:'50%', 
    height: '43px',
    textTransform: 'none', 
    border: '1px solid', 
    borderColor: '#D6DDEB',
    fontFamily: 'Plus Jakarta Sans', 
    color: 'black',
}))

const PasswordButton = styled(Button)(({theme}) => ({
    width:'100%', 
    height: '42px',
    textTransform: 'none', 
    backgroundColor: "#6F6AF8", 
    fontFamily: 'Plus Jakarta Sans',
    '&:hover': {
        backgroundColor: "#6F6AF8 !important"
    }
}))

const Profile = () => {
    const matches = useMediaQuery('(min-width:600px)');
	const [phone, setPhone] = React.useState('');
	const [countryCode, setCountryCode] = React.useState('');

	useEffect(() => {
		fetch('https://ipapi.co/json/')
			.then( res => res.json())
			.then(response => {
				setCountryCode(response.country_code);
		});
	}, []);

    return (
        <>
            <Grid container>
                <Grid item xs={12} >
                    <Grid container padding={matches?'20px 40px 20px 40px':'20px 20px 20px 20px'} spacing={3}>
                        <Grid item xs={matches?4:12} display='flex' justifyContent='left'>
                            <img src={AvatarIcon} alt="AvatarIcon" width='80px' />
                            <Container>
                                <Title>
                                    <strong>Tony Foster</strong>
                                </Title>
                                <Content>
                                    tony.fsstr@gmail.com
                                </Content>
                            </Container>
                        </Grid>
                        {matches?(
                        <>
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ marginRight: '20px' }} />
                        </>
                        ):(
                        <></>
                        )}
                        <Grid item xs={matches?4:12} display='flex' justifyContent='left'>
                            <img src={IncIcon} alt="IncIcon" width='80px'/>
                            <Container>
                                <Title>
                                    <strong>Pro Nature Inc</strong>
                                </Title>
                                <Content>
                                    Owner & Founder
                                </Content>
                            </Container>
                        </Grid>
                        {matches?(
                        <>
                            <Divider orientation="vertical" variant="middle" flexItem sx={{ marginRight: '20px' }} />
                            <Grid item xs={3} display='flex' justifyContent='center' alignItems='center'>
                                <EditButton>
                                    {/* <img src={GoogleIcon} style={{marginRight: '10px'}}/> */}
                                    <strong>Edit Information</strong>
                                </EditButton>
                            </Grid>
                        </>
                        ):(
                        <></>
                        )}
                    </Grid>
                    <Divider/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3} padding={matches?'20px 40px 20px 40px':'20px'}>
                        <Grid item xs={12}>
                            <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '20px'}}><b>Personal Information</b></Typography>
                        </Grid>
                        <Grid item xs={matches?3:12}>
                            <Grid container spacing={2}>
                                <Grid item xs={matches?12:6}>
                                    <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px'}}>First Name</Typography>
                                    <FormControl sx={{ width: '100%', marginTop: '10px' }} size="small">
                                        <OutlinedInput value="Tony" />
                                    </FormControl>                        
                                </Grid>
                                <Grid item xs={matches?12:6}>
                                    <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px'}}>Last Name</Typography>
                                    <FormControl sx={{ width: '100%', marginTop: '10px' }} size="small">
                                        <OutlinedInput value="Foster" />
                                    </FormControl>                        
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={matches?3:12}>
                            <Grid container spacing={2}>
                                <Grid item xs={matches?12:6}>
                                    <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px'}}>Gender</Typography>
                                    <FormControl sx={{ width: '100%', marginTop: '10px' }} size="small">
                                        <OutlinedInput value="Male" />
                                    </FormControl>                        
                                </Grid>
                                <Grid item xs={matches?12:6}>
                                    <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px'}}>Birthday</Typography>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                        <DemoContainer
                                            sx={{padding:"0 !important", marginTop: '10px' }}
                                            components={[
                                            'DesktopDatePicker',
                                            ]}
                                        >
                                            <DemoItem>
                                                <DesktopDatePicker className="dob" />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={matches?3:12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px'}}>Email</Typography>
                                    <FormControl sx={{ width: '100%', marginTop: '10px' }} size="small">
                                        <OutlinedInput value="tony.fsstr@gmail.com" />
                                    </FormControl>                        
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px'}}>Phone Number</Typography>
                                    <PhoneInput
                                        style={{ marginTop: '10px' }}
                                        country={countryCode.toLowerCase()}
                                        placeholder="Enter phone number"
                                        value={phone}
                                        onChange={setPhone}
                                        inputStyle={{
                                            width: "100%",
                                            height: "3em",
                                        }}
                                    />                  
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={matches?3:12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px'}}>Company Name</Typography>
                                    <FormControl sx={{ width: '100%', marginTop: '10px' }} size="small">
                                        <OutlinedInput value="Pro Nature Inc" />
                                    </FormControl>                        
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px'}}>Title</Typography>
                                    <FormControl sx={{ width: '100%', marginTop: '10px' }} size="small">
                                        <OutlinedInput value="Owner & Founder" />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Divider sx={{marginTop:'10px'}}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container padding={matches?'20px 40px 20px 40px':'20px'}>
                        <Grid item xs={12}>
                            <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '20px'}}><b>Password</b></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '14px', color: '#84818A'}}>You changed your password on <span style={{color: '#6F6AF8'}}>14th March 2023</span></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px', marginTop: '30px'}}><b>Current Password</b></Typography>
                        </Grid>
                        <Grid item xs={matches?4:12}>
                            <FormControl sx={{ width: '100%', marginTop: '10px' }} size="small">
                                <OutlinedInput value="Owner & Founder" type="password" />
                            </FormControl>
                        </Grid>
                        {matches?(<>
                        <Grid item xs={8}>
                        </Grid>
                        </>):(<></>)
                        }
                        <Grid item xs={matches?2:12}>
                            <PasswordButton variant="contained" sx={{ marginTop: '20px' }}>
                                Change Password
                            </PasswordButton>
                        </Grid>
                    </Grid>
                    <Divider sx={{marginTop:'10px'}}/>
                </Grid>
                <Grid item xs={12} sx={{display: 'flex', justifyContent: 'right'}}>
                    <Grid container padding={matches?'20px 40px 20px 40px':'20px'}>
                        {matches?(<>
                        <Grid item xs={9.6}>
                        </Grid>
                        </>):(<></>)
                        }
                        <Grid item xs={matches?1:6} sx={{marginRight: matches?'30px':'0', display:'flex', justifyContent:'left'}}>
                            <EditButton sx={{width: matches?'100%':'95%'}}><b>Discard</b></EditButton>
                        </Grid>
                        <Grid item xs={matches?1:6} sx={{display:'flex', justifyContent:'right'}}>
                            <PasswordButton variant="contained" sx={{width: matches?'100%':'95%'}}>Save</PasswordButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

export default Profile;

