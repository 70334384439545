import React from 'react';
import Header from '../../../layout/Header/Header';
// import Navigation from '../../../layout/Navigation/Navigation';
// import { componentPagesMenu, pageLayoutTypesPagesMenu } from '../../../menu';
// import useDeviceScreen from '../../../hooks/useDeviceScreen';
// import CommonHeaderRight from './CommonHeaderRight';

const DefaultHeader = () => {
	return (
		<Header>
		</Header>
	);
};

export default DefaultHeader;
