import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    Grid,
    IconButton,
    Tooltip,
    FormControlLabel,
    Switch,
    Checkbox,
    Button,
    FormControl, 
    OutlinedInput, 
    Link, 
    Select, 
    MenuItem
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import useMediaQuery from '@mui/material/useMediaQuery';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import '../styles/MyAssistant.css';
import UploaderIcon from '../assets/img/Uploader.svg';
import TrashIcon from '../assets/img/Trash.svg';
import DownloadIcon from '../assets/img/Download.svg';
import FileIcon from '../assets/img/File.svg';
import ReadyToUseIcon from '../assets/img/ReadyToUse.svg'
import InactiveIcon from '../assets/img/Inactive.svg';

import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import Pagination from '@mui/material/Pagination';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';


const fileTypes = ["csv", "xslm"];

const ContentTitle = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '12px',
    marginLeft: '10px',
    color: '#84818A',
}))

const ContentText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    marginLeft: '10px',
}))

const BoxContent = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 700,
}))

const Title = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '26px',
}))

const Content = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 700,
}))

function createData(no, name, dateuploaded, type, status, action) {
    return {
        no,
        name,
        dateuploaded,
        type,
        status,
        action,
    };
}

const rows = [
    createData(1, 'Sales_Dataset.xslm', '12 Feb 2023 4:32 PM', 'Excel', true, ''),
    createData(2, 'E-Commerce_Dataset.csv', '12 Feb 2023 4:32 PM', 'Google Sheet', false, ''),
    createData(3, 'Sales_Dataset.xslm', '12 Feb 2023 4:32 PM', 'Excel', true, ''),
    createData(4, 'E-Commerce_Dataset.csv', '12 Feb 2023 4:32 PM', 'Google Sheet', true, ''),
    createData(5, 'Sales_Dataset.xslm', '12 Feb 2023 4:32 PM', 'Google Sheet', false, ''),
    createData(6, 'E-Commerce_Dataset.csv', '12 Feb 2023 4:32 PM', 'Excel', false, ''),
    createData(7, 'February Sales_Dataset.xslm', '12 Feb 2023 4:32 PM', 'Excel', true, ''),
    createData(8, 'Sales_Dataset.xslm', '12 Feb 2023 4:32 PM', 'Google Sheet', false, ''),
    createData(9, 'E-Commerce_Dataset.csv', '12 Feb 2023 4:32 PM', 'Excel', false, ''),
    createData(10, 'February Sales_Dataset.xslm', '12 Feb 2023 4:32 PM', 'Excel', true, ''),
    createData(11, 'E-Commerce_Dataset.csv', '12 Feb 2023 4:32 PM', 'Excel', false, ''),
];
  
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
}
  
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
  
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
      id: 'no',
      numeric: true,
      disablePadding: false,
      label: '#',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'File Name',
    },
    {
      id: 'dateuploaded',
      numeric: false,
      disablePadding: false,
      label: 'Date Uploaded',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: false,
      label: 'Type',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      label: 'Status',
    },
    {
      id: 'action',
      numeric: false,
      disablePadding: false,
      label: 'Action',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead sx={{fontFamily: 'Plus Jakarta Sans', backgroundColor: '#F8F8F8', border: '1px solid #F2F2F2', borderRadius: '4px'}}>
        <TableRow>
            <TableCell padding="checkbox" sx={{ color: '#737373' }}>
                <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                    'aria-label': 'select all desserts',
                }}
                />
            </TableCell>
            {headCells.map((headCell) => (
            <TableCell
                key={headCell.id}
                align={'left'}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{ color: '#737373', padding: '0px' }}
            >
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                    ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
}
  
EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
  


const UploadFiles = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const [status, setStatus] = React.useState('Ready to use');
    const navigate = useNavigate();
    const [file, setFile] = React.useState(null);
    const handleChange = (file) => {
        setFile(file);
    };


    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(7);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
        const newSelected = rows.map((n) => n.no);
        setSelected(newSelected);
        return;
        }
        setSelected([]);
    };

    const handleClick = (event, no) => {
        const selectedIndex = selected.indexOf(no);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, no);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    const isSelected = (no) => selected.indexOf(no) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
        stableSort(rows, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
        ),
        [order, orderBy, page, rowsPerPage],
    );

    const handleStatusChange = (event) => {
        setStatus(event.target.value);
    };
    

    const Uploader = (
        <Grid container sx={{padding: '20px'}}>
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                <img src={UploaderIcon} width={matches?"65":"35"} />
            </Grid>
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                <Typography sx={{ fontSize: matches?22:18 }} gutterBottom>
                    <b>Drag & Drop Your Files Or <span style={{color: '#6F6AF8'}}><u>Browse</u></span></b>
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center'}}>
                <Typography sx={{ fontSize: matches?14:14, color: '#84818A' }} gutterBottom>
                    Support Only Excel Or Google Sheets Files
                </Typography>
            </Grid>
        </Grid>
    );
    

    return (
        <>
            <Grid container sx={{ marginTop: '10px', backgroundColor: '#F2F2F2' }}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            paddingLeft: matches?'80px':'0px',
                            // paddingRight: matches?'10px':'10px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                m: 1,
                                width: '100%',
                                height: matches?'90vh':'',
                            },
                        }}
                    >
                        <Paper elevation={1}>
                            <Grid container sx={{ flexDirection: 'column' }}>
                                <Grid container>
                                    <Grid item xs={12} sx={{margin: matches?'30px 0 0 30px':'20px 0 0 20px'}}>
                                        <Title><b>Upload Your Files</b></Title>
                                    </Grid>
                                    {matches?(
                                        <>
                                            <Grid item xs={12} sx={{margin: '0 20px 0 30px'}}>
                                                <Content>Simplify Your Data Analysis Process 🚀  Upload Your Sheet Files Here & Use Our Chatbot To Get Instant Answers About Your Data.</Content>
                                            </Grid>
                                        </>
                                    ):(
                                        <></>
                                    )}
                                </Grid>

                                <Grid item xs={12} sx={{ margin: matches?'20px 30px 20px 30px':'20px 20px 20px 20px', border: '2px dashed #6F6AF8', borderRadius: '5px', backgroundColor: 'rgb(199 198 237)'}}>
                                    <FileUploader handleChange={handleChange} name="file" types={fileTypes} children={Uploader}/>
                                </Grid>
                                
                                {matches?(
                                <>
                                    <Grid item xs={12} sx={{ margin: '0px 30px 0 30px' }}>
                                        <Grid container>
                                            <Grid item xs={12} sx={{marginBottom: '20px'}}>
                                                <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontSize: 22 }}>
                                                    <b>Previous Uploads</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TableContainer>
                                                    <Table
                                                        sx={{ minWidth: 750 }}
                                                        aria-labelledby="tableTitle"
                                                        size={dense ? 'small' : 'medium'}
                                                    >
                                                        <EnhancedTableHead
                                                        numSelected={selected.length}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onSelectAllClick={handleSelectAllClick}
                                                        onRequestSort={handleRequestSort}
                                                        rowCount={rows.length}
                                                        />
                                                        <TableBody>
                                                            {visibleRows.map((row, index) => {
                                                                const isItemSelected = isSelected(row.no);
                                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                                return (
                                                                <TableRow
                                                                    hover
                                                                    onClick={(event) => handleClick(event, row.no)}
                                                                    role="checkbox"
                                                                    aria-checked={isItemSelected}
                                                                    tabIndex={-1}
                                                                    key={row.no}
                                                                    selected={isItemSelected}
                                                                    sx={{ cursor: 'pointer', backgroundColor: index%2==1?'#F8F8F8':'' }}
                                                                >
                                                                    <TableCell padding="checkbox" sx={{ fontFamily: 'Plus Jakarta Sans', padding: '5px' }}>
                                                                        <Checkbox
                                                                            color="primary"
                                                                            checked={isItemSelected}
                                                                            inputProps={{
                                                                            'aria-labelledby': labelId,
                                                                            }}
                                                                        />
                                                                        </TableCell>
                                                                    <TableCell
                                                                        sx={{ fontFamily: 'Plus Jakarta Sans', padding: '0px' }}
                                                                        component="th"
                                                                        id={labelId}
                                                                        scope="row"
                                                                        >
                                                                        <b>{(row.no)<10? '0'+row.no: row.no}</b>
                                                                    </TableCell>
                                                                    <TableCell align="left" sx={{ fontFamily: 'Plus Jakarta Sans', padding: '10px', display: 'flex', alignItems: 'center' }}>
                                                                        <img src={FileIcon} alt="FileIcon" style={{marginRight:'10px'}} />
                                                                        <b>{row.name}</b>
                                                                    </TableCell>
                                                                    <TableCell align="left" sx={{ fontFamily: 'Plus Jakarta Sans', padding: '0px' }}><b>{row.dateuploaded}</b></TableCell>
                                                                    <TableCell align="left" sx={{ fontFamily: 'Plus Jakarta Sans', padding: '0px' }}><b>{row.type}</b></TableCell>
                                                                    <TableCell align="left" sx={{ fontFamily: 'Plus Jakarta Sans', padding: '0px' }}>
                                                                        <Box sx={{width: 'max-content', backgroundColor: row.status?'#E6F9EE':'#FFF0E0', 
                                                                                padding: '4px 8px 4px 8px', borderRadius: '16px', color: row.status?'#27AE60':'#FF9F38',
                                                                                display: 'flex', alignItems: 'center'}}>
                                                                            <img src={row.status? ReadyToUseIcon:InactiveIcon} alt="Status"/>
                                                                            <b>{row.status?'Ready to Use': 'Inactive'}</b>
                                                                        </Box>
                                                                    </TableCell>
                                                                    <TableCell align="left" sx={{ fontFamily: 'Plus Jakarta Sans', padding: '0px' }}>
                                                                        <Button size="small" variant="outlined" sx={{border: '1px solid #f1dede', height: '30px', marginRight: '10px'}}>
                                                                            <img src={TrashIcon} alt="TrashIcon" height='20px' width='20px'/>
                                                                        </Button>
                                                                        <Button size="small" variant="outlined" sx={{border: '1px solid #f1dede', height: '30px', marginRight: '10px'}}>
                                                                            <img src={DownloadIcon} alt="DownloadIcon" height='20px' width='20px'/>
                                                                        </Button>
                                                                        <Button size="small" variant="outlined" sx={{border: '1px solid #f1dede', padding: '4px 20px 0 20px', height: '30px', textTransform: 'none'}}>
                                                                            {'View > '}
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                                );
                                                            })}
                                                        {emptyRows > 0 && (
                                                            <TableRow
                                                            style={{
                                                                height: (dense ? 33 : 53) * emptyRows,
                                                            }}
                                                            >
                                                            <TableCell colSpan={6} />
                                                            </TableRow>
                                                        )}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <TablePagination
                                                    rowsPerPageOptions={[7]}
                                                    component={"div"}
                                                    count={rows.length}
                                                    rowsPerPage={rowsPerPage}
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                    shape="rounded"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </>
                                ):(
                                <></>
                                )}
                            </Grid>
                        </Paper>
                        {matches?(
                            <></>
                        ):(
                            <>
                                <Paper elevation={1}>
                                    <Grid container>
                                        <Grid item xs={7} sx={{padding: '20px 0 0 20px'}}>
                                            <Title sx={{fontSize: '20px'}}><b>Previous Uploads</b></Title>
                                        </Grid>
                                        <Grid item xs={5} sx={{padding: '20px 20px 0 0px'}}>
                                            <FormControl sx={{ width: '100%' }}>
                                                {/* <InputLabel id="demo-simple-select-helper-label" disableAnimation>Age</InputLabel> */}
                                                <Select
                                                    value={status}
                                                    onChange={handleStatusChange}
                                                    displayEmpty
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                    size="small"
                                                >
                                                <MenuItem value={'male'}>Ready to Use</MenuItem>
                                                <MenuItem value={'female'}>Inactive</MenuItem>
                                                </Select>
                                                {/* <FormHelperText>Without label</FormHelperText> */}
                                            </FormControl> 
                                        </Grid>
                                        {rows.map((row, index) => {
                                            const isItemSelected = isSelected(row.no);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <Grid item xs={12} sx={{padding: '10px 20px 10px 20px'}}>
                                                    <Grid container sx={{border: '1px solid #E2E2E2', borderRadius: '8px', padding: '10px'}}>
                                                        <Grid item xs={11} sx={{display: 'flex', justifyContent: 'left', alignItems: 'center', marginBottom: '20px'}}>
                                                            <img src={FileIcon} alt="FileIcon" width={'30px'} style={{marginRight: '10px'}}/>
                                                            <BoxContent>{row.name}</BoxContent>
                                                        </Grid>
                                                        <Grid item xs={1} sx={{display: 'flex', justifyContent: 'right', alignItems: 'center', marginBottom: '20px'}}>
                                                            <MoreHorizIcon />
                                                        </Grid>
                                                        <Grid item xs={5} sx={{display: 'flex', justifyContent: 'left', alignItems: 'center', marginBottom: '10px'}}>
                                                            <BoxContent sx={{color: '#84818A'}}>{'Date Uploaded'}</BoxContent>
                                                        </Grid>
                                                        <Grid item xs={7} sx={{display: 'flex', justifyContent: 'right', alignItems: 'center', marginBottom: '10px'}}>
                                                            <BoxContent>{row.dateuploaded}</BoxContent>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'left', alignItems: 'center', marginBottom: '10px'}}>
                                                            <BoxContent sx={{color: '#84818A'}}>{'Type'}</BoxContent>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'right', alignItems: 'center', marginBottom: '10px'}}>
                                                            <BoxContent>{row.type}</BoxContent>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                                                            <BoxContent sx={{color: '#84818A'}}>{'Status'}</BoxContent>
                                                        </Grid>
                                                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'right', alignItems: 'center'}}>
                                                            <Box sx={{width: 'max-content', backgroundColor: row.status?'#E6F9EE':'#FFF0E0', 
                                                                    padding: '4px 8px 4px 8px', borderRadius: '14px', color: row.status?'#27AE60':'#FF9F38',
                                                                    display: 'flex', alignItems: 'center', fontSize: '14px'}}>
                                                                <img src={row.status? ReadyToUseIcon:InactiveIcon} alt="Status"/>
                                                                <b>{row.status?'Ready to Use': 'Inactive'}</b>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Paper>
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}; 


export default UploadFiles;

