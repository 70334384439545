import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import { Button, Avatar, Divider, List, ListItem, ListItemText, OutlinedInput, FormControl, InputAdornment } from "@mui/material";
import Container from '@mui/material/Container';
import VoiceCricleIcon from '../assets/img/VoiceCricle.svg';
import ItemIcon from '../assets/img/Item.svg';
import IncIcon from '../assets/img/IncIcon.svg';
import DBConnectionIcon from '../assets/img/DBConnectionIcon.svg';
import DatabaseIcon from '../assets/img/DatabaseIcon.svg';
import AvatarImage from '../assets/img/Avatar.svg';
import BotImage from '../assets/img/Bot.svg';
import FileAttachIcon from '../assets/img/FileAttach.svg';
import EmojiIcon from '../assets/img/Emoji.svg';
import RecordIcon from '../assets/img/Record.svg';
import SendIcon from '../assets/img/Send.svg';
import TotalSalesIcon from '../assets/img/TotalSales.svg';
import QuestionIcon from '../assets/img/Queries.svg';
import MenuIcon from '@mui/icons-material/Menu';
import '../styles/MyAssistant.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import MixedChart from "../components/MixedChart";
import { ExpandMore } from "@material-ui/icons";

import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
Chart.register(CategoryScale);

// import { XYPlot, XAxis, YAxis, LineChart } from 'reactochart';
// import 'reactochart/styles.css';


const BoxContainer = styled(Container)(({ theme }) => ({
    // '&:first-child': {
    //     '@media (min-width: 1200px)': {
    //         maxWidth: '100%'
    //     }
    // },
    // marginLeft: '10px',
    // marginRight: '0px',
    // marginTop: '20px',
    // width: '100%',
    padding: '15px',
    '&:last-child': {
        paddingBottom: '0px'
    }
}))

const ContentTitle = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '26px',
    marginLeft: '10px',
}))

const LeftChatContainter = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'start',
}))

const LeftChatText = styled(Typography)(({ theme }) => ({
    maxWidth: '60%',
    marginLeft: '10px',
    backgroundColor: '#6F6AF8',
    width: 'max-content',
    borderRadius: '0px 20px 20px 20px',
    padding: '10px',
    color: 'white',
    fontFamily: 'Plus Jakarta Sans',
}))

const RightChatContainter = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'end',
}))

const RightChatText = styled(Typography)(({ theme }) => ({
    maxWidth: '60%',
    marginRight: '10px',
    backgroundColor: 'white',
    width: 'max-content',
    borderRadius: '10px 10px 0px 10px',
    border: '1px solid',
    borderColor: '#E6E6E6',
    padding: '10px',
    color: 'black',
    fontFamily: 'Plus Jakarta Sans',
    textAlign: 'left',
}))

const Title = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '20px',
}))

const Content = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    color: '#84818A',
}))

const MyAssistant = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();

    const [messages, setMessages] = React.useState([
        {
            text: "How many bags were sold in our Tokyo store yesterday ?",
            type: 'text',
            timestamp: 1578366389250,
        },
        {
            text: "247 bags were sold yesterday in your store in Tokyo, which represents 21% of your total sales for the same day.",
            type: 'text',
            timestamp: 1578366389250,
        },
        {
            text: "Great, What was the revenue and profit generated by our online sales this Monday?",
            type: 'text',
            timestamp: 1578366389250,
        },
        {
            text: "On Monday, your website generated a revenue of $245,526 and a profit of $47,421.",
            type: 'text',
            timestamp: 1578366389250,
        },
        {
            text: "Show me a chart comparing the sales made each Monday during the last month.",
            type: 'text',
            timestamp: 1578366389250,
        },
        {
            text: "How many bags were sold in our Tokyo store yesterday ?",
            type: 'text',
            timestamp: 1578366389250,
        },
        {
            text: "247 bags were sold yesterday in your store in Tokyo, which represents 21% of your total sales for the same day.",
            type: 'text',
            timestamp: 1578366389250,
        },
        {
            text: "Great, What was the revenue and profit generated by our online sales this Monday?",
            type: 'text',
            timestamp: 1578366389250,
        },
        {
            text: "On Monday, your website generated a revenue of $245,526 and a profit of $47,421.",
            type: 'text',
            timestamp: 1578366389250,
        },
        {
            text: "Show me a chart comparing the sales made each Monday during the last month.",
            type: 'text',
            timestamp: 1578366389250,
        },
        {
            text: "How many bags were sold in our Tokyo store yesterday ?",
            type: 'text',
            timestamp: 1578366389250,
        },
        {
            text: "247 bags were sold yesterday in your store in Tokyo, which represents 21% of your total sales for the same day.",
            type: 'text',
            timestamp: 1578366389250,
        },
        {
            text: "Great, What was the revenue and profit generated by our online sales this Monday?",
            type: 'text',
            timestamp: 1578366389250,
        },
        {
            text: "On Monday, your website generated a revenue of $245,526 and a profit of $47,421.",
            type: 'text',
            timestamp: 1578366389250,
        },
        {
            text: "Show me a chart comparing the sales made each Monday during the last month.",
            type: 'text',
            timestamp: 1578366389250,
        },
        // {
        //   text: "On Monday, your website generated a revenue of $245,526 and a profit of $47,421.",
        //   type: 'text',
        //   timestamp: 1578366389250,
        // }
    ]);

    const handleOnSendMessage = (message) => {
        setMessages(
            messages.concat({})
        );
    };


    return (
        <>
            <Grid container sx={{ marginTop: '10px', backgroundColor: '#F2F2F2' }}>
                <Grid item xs={matches?8:12}>
                    <Box
                        sx={{
                            paddingLeft: matches?'80px':'0px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                m: 1,
                                width: '100%',
                                height: matches?'90vh':'',
                            },
                        }}
                    >
                        <Paper elevation={1}>
                            <Grid container sx={{ minHeight: '100%', flexDirection: 'column' }}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <BoxContainer >
                                            <Grid container>
                                                <Grid item xs={10} display='flex'>
                                                    <img src={VoiceCricleIcon} alt="VoiceCricleIcon" width='30px' />
                                                    <ContentTitle sx={{fontSize: matches?'26px':'20px'}}><strong>My Assistant</strong></ContentTitle>
                                                </Grid>
                                                <Grid item xs={2} display='flex' justifyContent='right' alignItems='center' mt={'5px'}>
                                                    <Avatar
                                                        sx={{ bgcolor: '#1DBF73', width: matches?'80px':'10px', height: matches?'30px':'10px', fontSize: '16px', borderRadius: matches?'20px':'50%', marginRight: '10px' }}
                                                    >
                                                        {matches?'Active':''}
                                                    </Avatar>
                                                    {matches?(
                                                        <img src={ItemIcon} style={{ height: '30px', cursor: 'pointer' }} />
                                                    ):(<></>)}
                                                </Grid>
                                            </Grid>
                                        </BoxContainer>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <BoxContainer >
                                            <Grid container>
                                                <Grid item xs={matches?3:5} display={'flex'} justifyContent='left'>
                                                    <img src={IncIcon} alt="IncIcon" />
                                                    <Container>
                                                        <Title sx={{fontSize: matches?'20px':'12px'}}>
                                                            <strong>Pro Nature Inc</strong>
                                                        </Title>
                                                        <Content sx={{fontSize: matches?'16px':'8px'}}>
                                                            hello@pronature.co
                                                        </Content>
                                                    </Container>
                                                </Grid>
                                                <Divider orientation="vertical" variant="middle" flexItem sx={{ marginRight: matches?'10px':'5px', marginLeft: matches?'10px':'5px' }} />
                                                <Grid item xs={matches?3:5} display={'flex'} justifyContent='left'>
                                                    <img src={DBConnectionIcon} alt="DBConnectionIcon" />
                                                    <Container>
                                                        <Content sx={{fontSize: matches?'16px':'8px'}}>
                                                            Connection Type
                                                        </Content>
                                                        <Title sx={{fontSize: matches?'20px':'12px'}}>
                                                            <strong>Database</strong>
                                                        </Title>
                                                    </Container>
                                                </Grid>
                                                {matches?(<>
                                                <Divider orientation="vertical" variant="middle" flexItem sx={{ marginRight: '20px' }} />
                                                <Grid item xs={4} display={'flex'} justifyContent='left'>
                                                    <img src={DatabaseIcon} />
                                                    <Container>
                                                        <Content>
                                                            Database
                                                        </Content>
                                                        <Title>
                                                            <strong>ACX_JW Data Point</strong>
                                                        </Title>
                                                    </Container>
                                                </Grid></>):(<></>)}
                                            </Grid>
                                        </BoxContainer>
                                        <Divider />
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sx={{ flexDirection: 'column', display: 'flex'}}>
                                    {/* <List sx={{ overflowY: 'auto', maxHeight: 'calc(100% - 275px)'  }}> */}
                                    <BoxContainer>
                                        <List sx={{ overflowY: 'auto', maxHeight: '560px'  }}>
                                            {messages.map((message, index) => {
                                                if (index % 2 === 0) {
                                                    return (
                                                        <ListItem key={index} sx={{padding: matches?'':0}}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <ListItemText align="left">
                                                                        <LeftChatContainter>
                                                                            <img src={AvatarImage} alt='Avatar' />
                                                                            <LeftChatText>{message.text}</LeftChatText>
                                                                        </LeftChatContainter>
                                                                    </ListItemText>
                                                                </Grid>
                                                            </Grid>
                                                        </ListItem>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <ListItem key={index}>
                                                            <Grid container>
                                                                <Grid item xs={12}>
                                                                    <ListItemText align="right">
                                                                        <RightChatContainter>
                                                                            <RightChatText>{message.text}</RightChatText>
                                                                            <img src={BotImage} alt="Bot" />
                                                                        </RightChatContainter>
                                                                    </ListItemText>
                                                                </Grid>
                                                            </Grid>
                                                        </ListItem>
                                                    )
                                                }
                                            })}
                                        </List>
                                    </BoxContainer>
                                </Grid>

                                <Grid item xs={12} style={{ padding: '20px', marginTop: 'auto' }}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <OutlinedInput placeholder="Ask your question here..." fullWidth sx={{ backgroundColor: '#EEECF0', height: '80px' }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Grid sx={{ padding: '10px' }}><img src={FileAttachIcon} alt='FileAttachIcom' /></Grid>
                                                    <Grid sx={{ padding: '10px' }}><img src={EmojiIcon} alt='EmojiIcon' /></Grid>
                                                    <Grid sx={{ padding: '10px' }}><img src={RecordIcon} alt='RecordIcon' /></Grid>
                                                    <Divider orientation="vertical" variant="middle" sx={{ marginRight: '20px' }} />
                                                    <Grid sx={{ padding: '10px' }}><img src={SendIcon} alt='SendIcon' /></Grid>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={matches?4:12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    '& > :not(style)': {
                                        m: 1,
                                        width: '100%',
                                        height: matches?'44vh':'',
                                    },
                                }}
                            >
                                <Paper elevation={1}>
                                    <Grid container>
                                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'left', padding: '20px 20px 0px 20px'}}>
                                            <img src={TotalSalesIcon} alt="TotalSalesIcon" width={'30px'}/>
                                            <Typography sx={{color: '#3C8EF9', fontSize: '24px', marginLeft: '14px'}}>Total Sales</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'right', padding: '0px 20px 0px 20px'}}>
                                            <Typography sx={{color: '#1DBF73', fontSize: '16px'}}><b>+1.5M</b></Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'left', padding: '0px 20px 10px 65px'}}>
                                            <Typography sx={{color: 'black', fontSize: '32px'}}><b>$7.28M</b></Typography>
                                        </Grid>
                                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'right', alignItems: 'center', padding: '0px 20px 10px 65px'}}>
                                            <Typography sx={{color: 'black', fontSize: '16px', color: '#84818A'}}><b>This Month</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{marginLeft: '50px', marginRight: '50px'}}>
                                            <hr />                                        
                                        </Grid>
                                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'left', padding: '10px 20px 0px 50px'}}>
                                            <Typography sx={{color: '#84818A', fontSize: '16px'}}><b>SALES OVER TIME</b></Typography>
                                        </Grid>
                                    </Grid>
                                        {/* <MixedChart /> */}
                                </Paper>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    paddingTop: '0.3vh',
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    '& > :not(style)': {
                                        m: 1,
                                        width: '100%',
                                        height: matches?'44vh':'',
                                    },
                                }}
                            >
                                <Paper elevation={1} >
                                    <Grid container>
                                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'left', padding: '20px 0px 0px 20px'}}>
                                            <img src={QuestionIcon} alt="QuestionIcon" width={'30px'}/>
                                            <Typography sx={{fontFamily: 'Plus Jakarta Sans', color: '#3C8EF9', fontSize: matches?'24px':'20px', marginLeft: '14px'}}>Most used queries</Typography>
                                        </Grid>
                                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'left', alignItems: 'center', padding: '10px 0px 10px 65px'}}>
                                            <Typography sx={{fontFamily: 'Plus Jakarta Sans', color: 'black', fontSize: matches?'16px':'12px', color: '#84818A'}}>You've searched 21 queries last 7 days</Typography>
                                        </Grid>
                                        <Grid item xs={0.5} />
                                        <Grid item xs={11} sx={{display: 'flex', justifyContent: 'left', marginTop: '10px', padding: '10px 0px 10px 3px', backgroundColor: '#F6F7F7', borderRadius: '8px'}}>
                                            <MenuIcon sx={{color: '#84818A', padding: '0 10px 0 10px'}}/>
                                            <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px', width: '80%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}><b>When people come to you for help, what do they usually want help with?</b></Typography>
                                        </Grid>
                                        <Grid item xs={0.5} />
                                        <Grid item xs={0.5} />
                                        <Grid item xs={11} sx={{display: 'flex', justifyContent: 'left', marginTop: '10px', padding: '10px 0px 10px 3px', backgroundColor: '#F6F7F7', borderRadius: '8px'}}>
                                            <MenuIcon sx={{color: '#84818A', padding: '0 10px 0 10px'}}/>
                                            <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px', width: '500px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}><b>Have you ever saved someone's life?</b></Typography>
                                        </Grid>
                                        <Grid item xs={0.5} />
                                        <Grid item xs={0.5} />
                                        <Grid item xs={11} sx={{display: 'flex', justifyContent: 'left', marginTop: '10px', padding: '10px 0px 10px 3px', backgroundColor: '#F6F7F7', borderRadius: '8px'}}>
                                            <MenuIcon sx={{color: '#84818A', padding: '0 10px 0 10px'}}/>
                                            <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px', width: '500px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}><b>What's the best thing you got from one of your parents?</b></Typography>
                                        </Grid>
                                        <Grid item xs={0.5} />
                                        <Grid item xs={0.5} />
                                        <Grid item xs={11} sx={{display: 'flex', justifyContent: 'left', marginTop: '10px', padding: '10px 0px 10px 3px', backgroundColor: '#F6F7F7', borderRadius: '8px'}}>
                                            <MenuIcon sx={{color: '#84818A', padding: '0 10px 0 10px'}}/>
                                            <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px', width: '500px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}><b>Do you have any nicknames?</b></Typography>
                                        </Grid>
                                        <Grid item xs={0.5} />
                                        <Grid item xs={0.5} />
                                        <Grid item xs={11} sx={{display: 'flex', justifyContent: 'left', marginTop: '10px', padding: '10px 0px 10px 3px', backgroundColor: '#F6F7F7', borderRadius: '8px'}}>
                                            <MenuIcon sx={{color: '#84818A', padding: '0 10px 0 10px'}}/>
                                            <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px', width: '500px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}><b>What have you created that you are most proud of?</b></Typography>
                                        </Grid>
                                        <Grid item xs={0.5} />
                                        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'center', marginTop: '10px', padding: '10px 0px 10px 3px', borderRadius: '8px'}}>
                                            <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '16px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}><b>View All</b></Typography>
                                            <ExpandMore />
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

export default MyAssistant;

