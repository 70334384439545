import React from "react";
import { mainMenu } from '../menu';
import DefaultHeader from "../pages/_layout/_headers/DefaultHeader";

const headers = [
    { path: mainMenu.login.path, element: null },
    { path: mainMenu.signUp.path, element: null },
    { path: mainMenu.forgotPassword.path, element: null },
    { path: mainMenu.checkEmail.path, element: null },
    { path: mainMenu.resetPassword.path, element: null },
    { path: '*', element: <DefaultHeader /> },
]

export default headers;