import React from "react";
import { styled } from '@mui/material/styles';
import { Grid, Typography, Button } from "@mui/material";
import DatabaseConnectionIcon from '../assets/img/DatabaseConnection.svg';
import DatabaseModal from '../components/DatabaseModal';
import useMediaQuery from '@mui/material/useMediaQuery';

const Title = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '30px',
}))

const Content = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    color: '#84818A',
}))

const CustomButton = styled(Button)(({theme}) => ({
    width:'100%', 
    height: '43px',
    textTransform: 'none', 
    backgroundColor: "#6F6AF8", 
    fontFamily: 'Plus Jakarta Sans',
    '&:hover': {
        backgroundColor: "#6F6AF8 !important"
    }
}))

const DatabaseConnection = ({handleLogin}) => {
    const matches = useMediaQuery('(min-width:600px)');
    // const [isLogin, setIsLogin] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Grid container sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20vh', justifyContent: 'center'}}>
                <Grid item xs={12}>
                    <img src={DatabaseConnectionIcon} width={matches? '200px':'100px'}/>
                </Grid>
                <Grid item xs={12}>
                    <Title sx={{fontSize: matches? '30px':'22px'}}><b>No Database Connected</b></Title>
                </Grid>
                {matches?(
                <>
                    <Grid item xs={12} sx={{marginTop: '1vh'}}>
                        <Content>Experience the power of Datasking by seamlessly connecting your database today !</Content>
                    </Grid>
                </>
                    ):(
                <>
                    <Grid item xs={12} sx={{marginTop: '1vh'}}>
                        <Content>Experience the power of Datasking by</Content>
                    </Grid>
                    <Grid item xs={12}>
                        <Content>seamlessly connecting your database today !</Content>
                    </Grid>
                </>
                )}
                <Grid item xs={12} sx={{marginTop: '3vh'}}>
                    <CustomButton variant="contained" onClick={handleOpen}>Connect Database</CustomButton>
                </Grid>
            </Grid>

            <DatabaseModal open={open} handleClose={handleClose} handleLogin={handleLogin}/>
        </>
    )
}

export default DatabaseConnection;
