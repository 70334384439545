import React, { Suspense } from "react";
import ContentRoutes from './ContentRoutes';

const Content = () => {
    return (
		<main className='content'>
			{/* <Suspense> */}
				<ContentRoutes />
			{/* </Suspense> */}
		</main>
    )
}

export default Content;