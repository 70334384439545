import * as React from 'react';
import { Grid, Typography, Button, Fade, Modal, Checkbox, Backdrop, Box, Select, FormControl, OutlinedInput, MenuItem } from '@mui/material';
import Close from '@mui/icons-material/Close';
import CustomizedCheckbox from './icons/ChckedBox';
import styled from 'styled-components';
import { blue } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MSSQLServerIcon from '../assets/img/MSSQLServer.svg';
import MySQLIcon from '../assets/img/MySQL.svg';
import PostgreSQLIcon from '../assets/img/PostgreSQL.svg';
import BackIcon from '../assets/img/Back.svg';
import MariaDBIcon from '../assets/img/MariaDB.svg';
import OracleIcon from '../assets/img/Oracle.svg';
import MongoDBIcon from '../assets/img/MongoDB.svg';
import OtherDBIcon from '../assets/img/OtherDB.svg';
import SecureLogInIcon from '../assets/img/SecureLogIn.svg';
import DisconnectIcon from '../assets/img/Disconnect.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
};

const mobileStyle = {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
}

const Content = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans', 
    fontSize: '16px',
}))

const RememberGrid = styled(Grid)(({theme}) => ({
    display: 'flex',
    justifyContent: 'left'
}))

const CustomButton = styled(Button)(({theme}) => ({
    width:'100%', 
    height: '50px',
    textTransform: 'none', 
    backgroundColor: "#6F6AF8", 
    fontFamily: 'Plus Jakarta Sans',
    '&:hover': {
        backgroundColor: "#6F6AF8 !important"
    }
}))



const ChildModal = ({ handleLoginConnect }) => {
    const [open, setOpen] = React.useState(false);
    const [authMethod, setAuthMethod] = React.useState('SQL Server Authentication');
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const handleAuthMethodChange = (event) => {
        setAuthMethod(event.target.value);
    };
    
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };

    return (
      <React.Fragment>
        <CustomButton variant="contained" sx={{width:'100%', textTransform: 'none', backgroundColor: "#6F6AF8", fontFamily: 'Plus Jakarta Sans'}} onClick={handleOpen}>
            Continue
        </CustomButton>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Grid container sx={mobileStyle}>
                    <Grid item xs={2} padding='30px 0px 10px 30px' display='flex' justifyContent='left' alignItems='center'>
                        <Box sx={{border: '2px solid #dfdfdf',  borderRadius: '6px', width: '20px', height: '20px', display:'flex', justifyContent:'center'}}>
                            <img src={BackIcon} alt='BackIcon' width='14px' style={{cursor: 'pointer'}} onClick={() => handleClose()}/>
                        </Box>
                    </Grid>
                    <Grid item xs={10} padding='30px 0px 10px 0px' display='flex' justifyContent='center' alignItems='center'>
                        <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '20px'}}>
                            <b>Database Connection</b>
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={12} display={'flex'} justifyContent={'right'} padding='20px 20px 0 20px'>
                        <Close sx={{cursor: 'pointer'}} onClick={() => handleClose()}/>
                    </Grid> */}
                    <hr style={{width: '100%', height: '0px'}} />
                    <Grid item xs={12} padding='20px 20px 20px 20px'>
                        <Grid container>
                            <Grid item xs={12} marginBottom={'20px'}>
                                <Typography id="transition-modal-title" variant="h5" component="h2">
                                    <b>Server Details</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Content><b>Server Name</b></Content>
                                <FormControl sx={{ width: '100%', margin: '10px 0 10px 0', backgroundColor: 'white', borderRadius: '3px' }} size="small">
                                    <OutlinedInput value="https://1.446.56.6.98:90008" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Content><b>Authentication Method</b></Content>
                                <FormControl sx={{ width: '100%', margin: '10px 0 10px 0', backgroundColor: 'white', borderRadius: '3px' }}>
                                    <Select
                                        value={authMethod}
                                        onChange={handleAuthMethodChange}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        size="small"
                                    >
                                        <MenuItem value={'male'}></MenuItem>
                                    </Select>
                                </FormControl>  
                            </Grid>

                            <Grid item xs={12} sx={{margin: '10px 0 10px 0'}}>
                                <Typography id="transition-modal-title" variant="h5" component="h2">
                                    <b>Connect Account</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sx={{marginBottom: '20px'}}>                                    
                                <Grid container sx={{borderRadius: '6px', display: 'flex', alignItems: 'center', backgroundColor: '#DADDE5', padding: '10px'}}>
                                    <Grid item xs={2}>
                                        <img src={SecureLogInIcon} alt='SecureLogInIcon' width={'50px'} style={{paddingLeft: '10px'}}/>
                                    </Grid>
                                    <Grid item xs={10} paddingLeft={'15px'}>
                                        <Content>Secure Login</Content>
                                        <Typography sx={{fontFamily: 'Plus Jakarta Sans', color: '#84818A', fontSize: '12px'}}>Your personal information is secure on our website</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Content><b>Username</b></Content>
                                <FormControl sx={{ width: '100%', margin: '10px 0 10px 0', backgroundColor: 'white', borderRadius: '3px' }} size="small">
                                    <OutlinedInput placeholder="Enter username" />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Content><b>Password</b></Content>
                                <FormControl sx={{ width: '100%', margin: '10px 0 10px 0', backgroundColor: 'white', borderRadius: '3px' }} size="small">
                                    <OutlinedInput placeholder="Enter Password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <RememberGrid item xs={12}>
                                <Checkbox
                                    defaultChecked
                                    sx={{
                                        padding: '0 10px 0 0',
                                        color: blue[800],
                                        '&.Mui-checked': {
                                            color: blue[600],
                                        },
                                    }}
                                />
                                <Content><b>Remember Me</b></Content>
                            </RememberGrid>
                            <Grid item xs={12} sx={{marginTop: '20px', marginBottom: 'auto'}}>
                                <CustomButton variant="contained" onClick={handleLoginConnect}>
                                    <img src={DisconnectIcon} alt="DisconnectIcon" style={{marginRight: '10px'}}/>
                                    Login & Connect
                                </CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Fade>
        </Modal>
      </React.Fragment>
    );
  }

const DatabaseModal = ( {open, handleClose, handleLogin} ) => {
    const matches = useMediaQuery('(min-width:600px)');
    const [authMethod, setAuthMethod] = React.useState('SQL Server Authentication');
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const handleAuthMethodChange = (event) => {
        setAuthMethod(event.target.value);
    };

    const handleLoginConnect = () => {
        handleClose();
        handleLogin();
    }

    if (!open) return null;

    return (
        <>
            {matches?(
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={open}>
                        <Grid container sx={style}>
                            <Grid item xs={6} padding='20px 20px 0 20px'>
                                <Typography id="transition-modal-title" variant="h6" component="h2">
                                    Database Connection
                                </Typography>
                            </Grid>
                            <Grid item xs={6} display={'flex'} justifyContent={'right'} padding='20px 20px 0 20px'>
                                <Close sx={{cursor: 'pointer'}} onClick={() => handleClose()}/>
                            </Grid>
                            <hr style={{width: '100%'}} />
                            <Grid item xs={6} padding='20px 40px 20px 20px'>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography id="transition-modal-title" variant="h5" component="h2">
                                            <b>Select Database Type</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '12px', color: '#84818A',}}>
                                            Choose your desired database type to establish the appropriate connection.
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sx={{margin: '30px 0 10px 0'}}>
                                        <Grid container sx={{border: '2px solid #6F6AF8', borderRadius: '6px', display: 'flex', alignItems: 'center', backgroundColor: '#DBEDFF', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={MSSQLServerIcon} alt='MSSQLServerIcon' width={'30px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Content>
                                                    <b>Microsoft SQL Server</b>
                                                </Content>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomizedCheckbox checked/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} sx={{margin: '10px 0 10px 0'}}>
                                        <Grid container sx={{border: '2px solid #E6E6E6', borderRadius: '6px', display: 'flex', alignItems: 'center', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={MySQLIcon} alt='MySQLIcon' width={'30px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Content>
                                                    <b>MySQL</b>
                                                </Content>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomizedCheckbox/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} sx={{margin: '10px 0 10px 0'}}>
                                        <Grid container sx={{border: '2px solid #E6E6E6', borderRadius: '6px', display: 'flex', alignItems: 'center', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={PostgreSQLIcon} alt='PostgreSQLIcon' width={'30px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Content>
                                                    <b>PostgreSQL</b>
                                                </Content>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomizedCheckbox/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} sx={{margin: '10px 0 10px 0'}}>
                                        <Grid container sx={{border: '2px solid #E6E6E6', borderRadius: '6px', display: 'flex', alignItems: 'center', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={MariaDBIcon} alt='MariaDBIcon' width={'30px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Content>
                                                    <b>MariaDB</b>
                                                </Content>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomizedCheckbox/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} sx={{margin: '10px 0 10px 0'}}>
                                        <Grid container sx={{border: '2px solid #E6E6E6', borderRadius: '6px', display: 'flex', alignItems: 'center', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={OracleIcon} alt='OracleIcon' width={'30px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Content>
                                                    <b>Oracle</b>
                                                </Content>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomizedCheckbox/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} sx={{margin: '10px 0 10px 0'}}>
                                        <Grid container sx={{border: '2px solid #E6E6E6', borderRadius: '6px', display: 'flex', alignItems: 'center', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={MongoDBIcon} alt='MongoDBIcon' width={'30px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Content>
                                                    <b>MongoDB</b>
                                                </Content>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomizedCheckbox/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} sx={{margin: '10px 0 10px 0'}}>
                                        <Grid container sx={{border: '2px solid #E6E6E6', borderRadius: '6px', display: 'flex', alignItems: 'center', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={OtherDBIcon} alt='OtherDBIcon' width={'30px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Content>
                                                    <b>Others</b>
                                                </Content>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomizedCheckbox/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>
                            </Grid>


                            <Grid item xs={6} padding='20px 20px 20px 50px' backgroundColor='#F5F5F5'>
                                <Grid container>
                                    <Grid item xs={12} marginBottom={'20px'}>
                                        <Typography id="transition-modal-title" variant="h5" component="h2">
                                            <b>Server Details</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Content><b>Server Name</b></Content>
                                        <FormControl sx={{ width: '100%', margin: '10px 0 10px 0', backgroundColor: 'white', borderRadius: '3px' }} size="small">
                                            <OutlinedInput value="https://1.446.56.6.98:90008" />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Content><b>Authentication Method</b></Content>
                                        <FormControl sx={{ width: '100%', margin: '10px 0 10px 0', backgroundColor: 'white', borderRadius: '3px' }}>
                                            <Select
                                                value={authMethod}
                                                onChange={handleAuthMethodChange}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                size="small"
                                            >
                                                <MenuItem value={'male'}></MenuItem>
                                            </Select>
                                        </FormControl>  
                                    </Grid>


                                    <Grid item xs={12} sx={{margin: '10px 0 10px 0'}}>
                                        <Typography id="transition-modal-title" variant="h5" component="h2">
                                            <b>Connect Account</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{marginBottom: '20px'}}>                                    
                                        <Grid container sx={{borderRadius: '6px', display: 'flex', alignItems: 'center', backgroundColor: '#DADDE5', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={SecureLogInIcon} alt='SecureLogInIcon' width={'50px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={10} paddingLeft={'10px'}>
                                                <Content>Secure Login</Content>
                                                <Typography sx={{fontFamily: 'Plus Jakarta Sans', color: '#84818A', fontSize: '12px'}}>Your personal information is secure on our website</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Content><b>Username</b></Content>
                                        <FormControl sx={{ width: '100%', margin: '10px 0 10px 0', backgroundColor: 'white', borderRadius: '3px' }} size="small">
                                            <OutlinedInput placeholder="Enter username" />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Content><b>Password</b></Content>
                                        <FormControl sx={{ width: '100%', margin: '10px 0 10px 0', backgroundColor: 'white', borderRadius: '3px' }} size="small">
                                            <OutlinedInput placeholder="Enter Password"
                                                type={showPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <RememberGrid item xs={12}>
                                        <Checkbox
                                            defaultChecked
                                            sx={{
                                                padding: '0 10px 0 0',
                                                color: blue[800],
                                                '&.Mui-checked': {
                                                    color: blue[600],
                                                },
                                            }}
                                        />
                                        <Content><b>Remember Me</b></Content>
                                    </RememberGrid>
                                    <Grid item xs={12} sx={{marginTop: '20px'}}>
                                        <CustomButton variant="contained" onClick={handleLoginConnect}>
                                            <img src={DisconnectIcon} alt="DisconnectIcon" style={{marginRight: '10px'}}/>
                                            Login & Connect
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fade>
                </Modal>
            </>
                ):(
            <>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <Fade in={open}>
                        <Grid container sx={mobileStyle}>
                            <Grid item xs={2} padding='30px 0px 10px 30px' display='flex' justifyContent='left' alignItems='center'>
                                <Box sx={{border: '2px solid #dfdfdf',  borderRadius: '6px', width: '20px', height: '20px', display:'flex', justifyContent:'center'}}>
                                    <img src={BackIcon} alt='BackIcon' width='14px' style={{cursor: 'pointer'}} onClick={() => handleClose()}/>
                                </Box>
                            </Grid>
                            <Grid item xs={10} padding='30px 0px 10px 0px' display='flex' justifyContent='center' alignItems='center'>
                                <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '20px'}}>
                                    <b>Database Connection</b>
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={12} display={'flex'} justifyContent={'right'} padding='20px 20px 0 20px'>
                                <Close sx={{cursor: 'pointer'}} onClick={() => handleClose()}/>
                            </Grid> */}
                            <hr style={{width: '100%', height: '0px'}} />
                            <Grid item xs={12} padding='20px 20px 20px 20px'>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography id="transition-modal-title" variant="h5" component="h2">
                                            <b>Select Database Type</b>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography sx={{fontFamily: 'Plus Jakarta Sans', fontSize: '12px', color: '#84818A',}}>
                                            Choose your desired database type to establish the appropriate connection.
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sx={{margin: '30px 0 10px 0'}}>
                                        <Grid container sx={{border: '2px solid #6F6AF8', borderRadius: '6px', display: 'flex', alignItems: 'center', backgroundColor: '#DBEDFF', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={MSSQLServerIcon} alt='MSSQLServerIcon' width={'35px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Content>
                                                    <b>Microsoft SQL Server</b>
                                                </Content>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomizedCheckbox checked/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} sx={{margin: '10px 0 10px 0'}}>
                                        <Grid container sx={{border: '2px solid #E6E6E6', borderRadius: '6px', display: 'flex', alignItems: 'center', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={MySQLIcon} alt='MySQLIcon' width={'35px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Content>
                                                    <b>MySQL</b>
                                                </Content>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomizedCheckbox/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} sx={{margin: '10px 0 10px 0'}}>
                                        <Grid container sx={{border: '2px solid #E6E6E6', borderRadius: '6px', display: 'flex', alignItems: 'center', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={PostgreSQLIcon} alt='PostgreSQLIcon' width={'35px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Content>
                                                    <b>PostgreSQL</b>
                                                </Content>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomizedCheckbox/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} sx={{margin: '10px 0 10px 0'}}>
                                        <Grid container sx={{border: '2px solid #E6E6E6', borderRadius: '6px', display: 'flex', alignItems: 'center', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={MariaDBIcon} alt='MariaDBIcon' width={'35px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Content>
                                                    <b>MariaDB</b>
                                                </Content>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomizedCheckbox/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} sx={{margin: '10px 0 10px 0'}}>
                                        <Grid container sx={{border: '2px solid #E6E6E6', borderRadius: '6px', display: 'flex', alignItems: 'center', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={OracleIcon} alt='OracleIcon' width={'35px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Content>
                                                    <b>Oracle</b>
                                                </Content>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomizedCheckbox/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} sx={{margin: '10px 0 10px 0'}}>
                                        <Grid container sx={{border: '2px solid #E6E6E6', borderRadius: '6px', display: 'flex', alignItems: 'center', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={MongoDBIcon} alt='MongoDBIcon' width={'35px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Content>
                                                    <b>MongoDB</b>
                                                </Content>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomizedCheckbox/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} sx={{margin: '10px 0 10px 0'}}>
                                        <Grid container sx={{border: '2px solid #E6E6E6', borderRadius: '6px', display: 'flex', alignItems: 'center', padding: '10px'}}>
                                            <Grid item xs={2}>
                                                <img src={OtherDBIcon} alt='OtherDBIcon' width={'35px'} style={{paddingLeft: '10px'}}/>
                                            </Grid>
                                            <Grid item xs={9}>
                                                <Content>
                                                    <b>Others</b>
                                                </Content>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <CustomizedCheckbox/>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} sx={{marginTop: '16px', marginBottom: 'auto'}}>
                                        <ChildModal handleLoginConnect={handleLoginConnect}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fade>
                </Modal>
            </>
            )}
        </>
    );
}

export default DatabaseModal;