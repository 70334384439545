import React from "react";
// import Brand from '../../../layout/Brand/Brand';
// import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';

const DefaultAside = () => {
    return (
        <>
            <Aside />
        </>
    )
}

export default DefaultAside;