import React, { useEffect } from "react";
// import LoginBackground from '../../assets/img/Login.svg';
import LoginBackground from '../../assets/img/Login.png';
import classNames from "classnames";
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid, Typography, FormControl, OutlinedInput, Checkbox, Link, Button, Select, MenuItem, InputLabel } from '@mui/material';
import { blue } from '@mui/material/colors';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { deDE } from '@mui/x-date-pickers/locales';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import '../../styles/Signup.css';
import Recaptcha from 'react-recaptcha';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';
import { countries } from 'country-data';
import { CountryDropdown } from 'react-country-region-selector';
import GoogleIcon from '../../assets/img/Google.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

const BackgroundGrid = styled(Grid)(({theme}) => ({
    width:'100%', 
    backgroundImage: "linear-gradient( rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6)), url(/img/SignUp.png)", 
    backgroundRepeat: 'no-repeat', 
    backgroundSize: '100% 100%',
}))

const BackgroundTitle = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans', 
    fontSize: '30px',
    color: 'white',
    textAlign: 'center'
}))

const BackgroundText = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans', 
    fontSize: '16px',
    color: 'white',
    textAlign: 'center'
}))

const ContentGrid = styled(Grid)(({theme}) => ({
}))

const LogoGrid = styled(Grid)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
}))

const ContentTitle = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans', 
    // marginTop: '100px',
    fontSize: '26px',
}))

const ContentText = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans', 
    fontSize: '12px',
    fontWeight: 500,
}))

const RememberGrid = styled(Grid)(({theme}) => ({
    display: 'flex',
    justifyContent: 'left'
}))

const CustomButton = styled(Button)(({theme}) => ({
    width:'100%', 
    height: '35px',
    textTransform: 'none', 
    backgroundColor: "#6F6AF8", 
    fontFamily: 'Plus Jakarta Sans',
    '&:hover': {
        backgroundColor: "#6F6AF8 !important"
    }
}))

const GoogleButton = styled(Button)(({theme}) => ({
    width:'100%', 
    height: '35px',
    textTransform: 'none', 
    border: '1px solid', 
    borderColor: '#D6DDEB',
    fontFamily: 'Plus Jakarta Sans', 
    color: 'black',
}))

const Signup = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const [gender, setGender] = React.useState('Male');
	const [phone, setPhone] = React.useState('');
	const [countryCode, setCountryCode] = React.useState('');
	const [ checkedCaptcha, setCheckedCaptcha ] = React.useState(() => false);
    const [ phoneNumber, setPhoneNumber ] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

	const backgroundColor = '#f8f9fa';
	const borderColor = '#f8f9fa';
  

	useEffect(() => {
		fetch('https://ipapi.co/json/')
			.then( res => res.json())
			.then(response => {
				setCountryCode(response.country_code);
		});
	}, []);
    
    const handleGenderChange = (event) => {
        setGender(event.target.value);
    };
    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };
	const verifyCaptcha = () => {
		setCheckedCaptcha(true);
	};
    return (
        <Box>
            <Grid container>
                {matches? (
                <>
                <BackgroundGrid item xs={5} sx={{height: matches?'100vh':''}}>
                    <div style={{marginTop: '80vh'}}>
                        <BackgroundTitle>Get next level insight</BackgroundTitle>
                        <BackgroundText>Uncover your business’s level of outcome, and<br />areas for opportunity.</BackgroundText>
                    </div>
                </BackgroundGrid>
                </>
                ):(
                <>
                </>)}
                <Grid item xs={matches? 7 : 12}>
                    <ContentGrid container spacing={2} sx={{ marginTop: matches?'20px':'10px', paddingLeft: matches?'30%':'5%', paddingRight: matches?'30%':'5%' }}>
                        <LogoGrid item xs={12}>
                            <img src="/img/Logo.png" alt="logo" width={matches?'250px':'200px'}></img>
                        </LogoGrid>
                        <Grid item xs={12}>
                            <ContentTitle><strong>Create Your Account</strong></ContentTitle>
                        </Grid>
                        <Grid item xs={12} sx={{paddingTop: '0px ! important'}}>
                            <ContentText sx={{color: '#84818A'}}>Already have an account? <Link href="/login" underline="none" sx={{color: '#6F6AF8', cursor: 'pointer'}}>Sign In Now</Link></ContentText>
                        </Grid>
                        <Grid item xs={6}>
                            <ContentText sx={{paddingBottom: '6px'}}>First Name</ContentText>
                            <FormControl sx={{ width: '100%' }} size="small">
                                <OutlinedInput sx={{fontFamily: 'Plus Jakarta Sans'}} placeholder="eg. John" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <ContentText sx={{paddingBottom: '6px'}}>Last Name</ContentText>
                            <FormControl sx={{ width: '100%' }} size="small">
                                <OutlinedInput sx={{fontFamily: 'Plus Jakarta Sans'}} placeholder="eg. Newman" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <ContentText sx={{paddingBottom: '6px'}}>Gender</ContentText>
                            <FormControl sx={{ width: '100%' }}>
                                {/* <InputLabel id="demo-simple-select-helper-label" disableAnimation>Age</InputLabel> */}
                                <Select
                                    value={gender}
                                    onChange={handleGenderChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    size="small"
                                >
                                <MenuItem value={'male'} sx={{fontFamily: 'Plus Jakarta Sans'}}>Male</MenuItem>
                                <MenuItem value={'female'} sx={{fontFamily: 'Plus Jakarta Sans'}}>Female</MenuItem>
                                </Select>
                                {/* <FormHelperText>Without label</FormHelperText> */}
                            </FormControl>                            
                        </Grid>
                        <Grid item xs={6}>
                            <ContentText sx={{paddingBottom: '6px'}}>Birthday</ContentText>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DemoContainer
                                    sx={{padding:"0 !important"}}
                                    components={[
                                    'DesktopDatePicker',
                                    ]}
                                >
                                    <DemoItem>
                                        <DesktopDatePicker className="dob" />
                                    </DemoItem>
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <ContentText sx={{paddingBottom: '6px'}}>Company Name</ContentText>
                            <FormControl sx={{ width: '100%' }} size="small">
                                <OutlinedInput sx={{fontFamily: 'Plus Jakarta Sans'}} placeholder="Enter Company Name" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <ContentText sx={{paddingBottom: '6px'}}>Title</ContentText>
                            <FormControl sx={{ width: '100%' }} size="small">
                                <OutlinedInput sx={{fontFamily: 'Plus Jakarta Sans'}} placeholder="Enter Title" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <ContentText sx={{paddingBottom: '6px'}}>Phone Number</ContentText>
                            {/* <FormControl sx={{ width: '100%' }} size="small"> */}
                                <PhoneInput
                                    country={countryCode.toLowerCase()}
                                    placeholder="Enter phone number"
                                    value={phone}
                                    onChange={setPhone}
                                    inputStyle={{
                                        width: "100%",
                                        height: "3em",
                                    }}
                                />
                                <input 
                                    type='hidden'
                                    autoComplete='phoneNumber'
                                    value={phoneNumber}
                                    // isTouched={formik.touched.phoneNumber}
                                    // invalidFeedback={
                                    //     formik.errors.phoneNumber
                                    // }
                                    // isValid={formik.isValid}
                                    onChange={handlePhoneNumberChange}
                                    // onBlur={formik.handleBlur}
                                    // onFocus={() => {
                                    //     formik.setErrors({});
                                    // }}
                                />
                            {/* </FormControl> */}
                        </Grid>
                        <Grid item xs={12}>
                            <ContentText sx={{paddingBottom: '6px'}}>Email</ContentText>
                            <FormControl sx={{ width: '100%' }} size="small">
                                <OutlinedInput sx={{fontFamily: 'Plus Jakarta Sans'}} placeholder="hello@email.com" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <ContentText sx={{paddingBottom: '6px'}}>Password</ContentText>
                            <FormControl sx={{ width: '100%' }} size="small">
                                <OutlinedInput sx={{fontFamily: 'Plus Jakarta Sans'}} placeholder="Enter Password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <ContentText sx={{paddingBottom: '6px'}}>Confirm Password</ContentText>
                            <FormControl sx={{ width: '100%' }} size="small">
                                <OutlinedInput sx={{fontFamily: 'Plus Jakarta Sans'}} placeholder="Confirm Password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <RememberGrid item xs={12}>
                            <Checkbox
                                defaultChecked
                                sx={{
                                    padding: '0 10px 0 0',
                                    color: '#6F6AF8',
                                    '&.Mui-checked': {
                                        color: '#6F6AF8',
                                    },
                                }}
                            />
                            <ContentText>By clicking Join now, I agree that I have read and accepted the <Link href="#" underline="none" sx={{color: '#6F6AF8', cursor: 'pointer'}}>Terms of Use</Link> and <Link href="#" underline="none" sx={{color: '#6F6AF8', cursor: 'pointer'}}>Privacy Policy</Link></ContentText>
                        </RememberGrid>
                        <Grid item xs={12}>
                            {/* <Recaptcha
                                sitekey="6LfP5uwlAAAAAIdUQx10SHHpTj8_80E_tJDlKFug"
                                type="image"
                                size='normal'
                                theme="dark"
                                render="explicit"
                                verifyCallback={verifyCaptcha}
                            /> */}
                        </Grid>
                        <Grid item xs={12}>
                            <CustomButton variant="contained">
                                Join Now
                            </CustomButton>
                        </Grid>
                        <Grid item xs={12}>
                            <GoogleButton>
                                <img src={GoogleIcon} style={{marginRight: '10px'}}/>
                                <strong>Continue with Google</strong>
                            </GoogleButton>
                        </Grid>
                    </ContentGrid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Signup;