import React from "react";
import { mainMenu } from "../menu";
import DefaultAside from '../pages/_layout/_asides/DefaultAside';


const asides = [
    { path: mainMenu.login.path, element: null },
    { path: mainMenu.signUp.path, element: null },
    { path: mainMenu.forgotPassword.path, element: null },
    { path: mainMenu.checkEmail.path, element: null },
    { path: mainMenu.resetPassword.path, element: null },
    { path: '*', element: <DefaultAside /> },
]

export default asides;