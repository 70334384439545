import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
// import AppBar from '@mui/material/AppBar';
// import Box from '@mui/material/Box';
// import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
// import Grid from '@mui/material/Grid';
// import InputBase from '@mui/material/InputBase';
// import Badge from '@mui/material/Badge';
// import MenuItem from '@mui/material/MenuItem';
// import Menu from '@mui/material/Menu';
// import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
// import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsIcon from '../../assets/img/Notification.svg';
import SearchIcon from '../../assets/img/Search.svg';
import FilterIcon from '../../assets/img/Filter.svg';
import MoreIcon from '@mui/icons-material/MoreVert';
import AvatarImage from '../../assets/img/Avatar.svg';
import DropDownIcon from '../../assets/img/DropDown.svg';
import { Typography, AppBar, Box, Toolbar, IconButton, Grid, InputBase, Badge, MenuItem, Menu } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: '8px',
    backgroundColor: '#EDEDED',
    alignContent: 'center',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '30%',
    display: 'flex',
    justifyContent: 'left'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#84818A',
}));

const FilterIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(1, 2),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    color: '#84818A',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    color: '#84818A',
    width: '100%',
    '& .MuiInputBase-input': {
        color: 'black',
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
    },
}));

const Name = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    color: 'black',
}))

const Role = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    color: 'blue',
}))

const Header = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
            >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
  );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
            >
            <MenuItem>
                <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                <Badge badgeContent={4} color="error">
                    <MailIcon />
                </Badge>
                </IconButton>
                <p>Messages</p>
            </MenuItem>
            <MenuItem>
                <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                >
                <Badge badgeContent={17} color="error">
                        <img src={NotificationsIcon} />
                </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                color="inherit"
                >
                <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
  );

  return (
    <>
        {matches?(
        <>
            <Box sx={{ flexGrow: 1, marginLeft: '60px' }}>
                <AppBar position="static" sx={{ backgroundColor: 'white' }}>
                    <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <img src="/img/Logo.png" width={'10%'}></img>
                    <Search>
                        <SearchIconWrapper>
                            <img src={SearchIcon} alt='SearchIcon'/>
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search for data, file, history etc..."
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <FilterIconWrapper>
                            <img src={FilterIcon} alt='FilterIcon'/>
                        </FilterIconWrapper>
                    </Search>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton
                        size="large"
                        aria-label="show 17 new notifications"
                        color="inherit"
                        >
                            <img src={NotificationsIcon} alt='NotificationsIcon'/>
                        </IconButton>
                        <IconButton
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls={menuId}
                        aria-haspopup="true"
                        onClick={handleProfileMenuOpen}
                        color="inherit"
                        >
                        {/* <AccountCircle /> */}
                        <Grid container display='flex' justifyContent='left' alignItems='center'>
                            <Grid item>
                                <img src={AvatarImage} alt='AvatarImage' />
                            </Grid>
                            <Grid item sx={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginLeft: '10px', justifyContent:'left'}}>
                                <Name>
                                    Tony Foster
                                </Name>
                                <Role>
                                    Pro Member
                                </Role>
                            </Grid>
                            <Grid item>
                                <img src={DropDownIcon} alt='DropDownIcon' />
                            </Grid>
                        </Grid>
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                        // size="large"
                        // aria-label="show more"
                        // aria-controls={menuId}
                        // aria-haspopup="true"
                        // onClick={handleMobileMenuOpen}
                        // color="inherit"
                        >
                        <img src={AvatarImage} alt='AvatarImage' />
                        </IconButton>
                    </Box>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
            </Box>
        </>
        ):(
        <>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static" sx={{ backgroundColor: 'white' }}>
                    <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <img src="/img/Logo.png" width={'40%'}></img>
                        <MenuIcon sx={{color:'black', width: '50px'}}/>
                    </Toolbar>
                </AppBar>
            </Box>
        </>
        )}
    </>
  );
}

export default Header;