import React from "react";
// import LoginBackground from '../../assets/img/Login.svg';
// import ForgotPassword from '../../assets/img/ForgotPassword.png';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid, Typography, FormControl, OutlinedInput, Checkbox, Link, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const BackgroundGrid = styled(Grid)(({theme}) => ({
    width:'100%', 
    height:'100vh', 
    // background: 'linear-gradient(to bottom, transparent 0%,transparent 50%,red 50%,red 100%)',
    backgroundImage: "linear-gradient( rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6)), url(/img/ForgotPassword.png)", 
    backgroundRepeat: 'no-repeat', 
    backgroundSize: '100% 100%',
}))

const BackgroundTitle = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '30px',
    color: 'white',
    textAlign: 'center'
}))

const BackgroundText = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    color: 'white',
    textAlign: 'center'
}))

const ContentGrid = styled(Grid)(({theme}) => ({
    // paddingLeft: '30%',
    // paddingRight: '30%'
}))

const LogoGrid = styled(Grid)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
}))

const ContentTitle = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '26px',
}))

const ContentText = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: '500'
}))

const CustomButton = styled(Button)(({theme}) => ({
    width:'100%', 
    height: '43px',
    textTransform: 'none', 
    backgroundColor: "#6F6AF8", 
    fontFamily: 'Plus Jakarta Sans',
    '&:hover': {
        backgroundColor: "#6F6AF8 !important"
    }
}))


const ForgotPassword = () => {
    const matches = useMediaQuery('(min-width:600px)');
    return (
        <Box>
            <Grid container>
                {matches? (
                <>
                <BackgroundGrid item xs={5}>
                    <div style={{marginTop: '80vh'}}>
                        <BackgroundTitle>Real-Time Data Intelligence</BackgroundTitle>
                        <BackgroundText>Effortlessly obtain instant data insights, anytime, <br/>anywhere, on any device.</BackgroundText>
                    </div>
                </BackgroundGrid>
                </>
                ):(
                <>
                </>)}
                <Grid item xs={matches? 7 : 12}>
                    <ContentGrid container spacing={2}>
                        <LogoGrid item xs={12} sx={{margin: matches?'100px 30% 10px 30%':'20px 10px 10px 10px'}}>
                            <img src="/img/Logo.png" width={matches?'250px':'200px'}></img>
                        </LogoGrid>
                        {matches? (
                        <>
                        </>
                        ):(
                        <>
                            <hr width='100%'/>
                        </>)}
                        <Grid item xs={12} sx={{ margin: matches?'100px 30% 10px 30%':'20px 20px 0px 20px' }}>
                            <ContentTitle><strong>Forgot Your Password?</strong></ContentTitle>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: matches?'0px 30% 10px 30%':'0px 20px 10px 20px', paddingTop:'0px !important' }}>
                            <ContentText sx={{color: '#84818A', fontSize: '14px'}}>Enter Your Email Address & Securely Reset Your Password</ContentText>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: matches?'0px 30% 10px 30%':'20px 20px 0px 20px'}}>
                            <ContentText>Email</ContentText>
                            <FormControl sx={{ width: '100%', marginTop: '10px' }} size="small">
                                <OutlinedInput sx={{fontFamily: 'Plus Jakarta Sans'}} placeholder="hello@email.com" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: matches?'0px 30% 10px 30%':'20px 20px 0px 20px'}}>
                            <CustomButton variant="contained">
                                Reset Password
                            </CustomButton>
                        </Grid>
                    </ContentGrid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ForgotPassword;