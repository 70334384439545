export const mainMenu = {
    myAssistant: {
        id: 'myAssistant',
        text: 'My Assistant',
        path: '/',
        // path: 'assistant',
        icon: 'Assistant',
    },
    uploadFiles: {
        id: 'uploadFiles',
        text: 'Upload Files',
        path: '/upload',
        icon: 'Upload',
    },
    database: {
        id: 'database',
        text: 'Database ',
        path: '/database',
        ison: 'Database',
    },
    settings: {
        id: 'settings',
        text: 'Settings',
        path: '/settings',
        icon: 'Settings',
    },
	login: {
		id: 'login',
		text: 'Login',
		path: '/login',
		icon: 'Login',
	},
	forgotPassword: {
		id: 'forgotPassword',
		text: 'Forgot Password',
		path: '/forgotpassword',
		icon: 'ForgotPassword',
	},
	checkEmail: {
		id: 'checkEmail',
		text: 'Check Email',
		path: '/checkemail',
		icon: 'CheckEmail',
	},
	resetPassword: {
		id: 'resetPassword',
		text: 'Reset Password',
		path: '/resetpassword',
		icon: 'ResetPassword',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: '/signup',
		icon: 'SignUp',
	},
	logout: {
		id: 'logout',
		text: 'Logout',
		path: '/logout',
		icon: 'Logout',
	},
}

