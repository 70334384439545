import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography } from "@mui/material";
import { Button, Tab, Divider, List, ListItem, ListItemText, OutlinedInput, FormControl, InputAdornment } from "@mui/material";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../styles/MyAssistant.css';
import Profile from '../components/Profile';
import UserManagement from "../components/UserManagement";

const ContentTitle = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '12px',
    marginLeft: '10px',
    color: '#84818A',
}))

const ContentText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    marginLeft: '10px',
}))

const Title = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '30px',
}))

const Content = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    color: '#84818A',
}))

const DisconnectButton = styled(Button)(({theme}) => ({
    width:'250px', 
    height: '43px',
    textTransform: 'none', 
    border: '1px solid', 
    borderColor: '#D6DDEB',
    backgroundColor: "#F02532", 
    fontFamily: 'Plus Jakarta Sans', 
    color: 'white',
}))


const Settings = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const [value, setValue] = React.useState('1');
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
        <>
            <Grid container sx={{ marginTop: '10px', backgroundColor: '#F2F2F2' }}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            paddingLeft: matches?'80px':'0px',
                            // paddingRight: matches?'10px':'10px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                m: 1,
                                width: '100%',
                                height: matches?'90vh':'',
                            },
                        }}
                    >
                        <Paper elevation={1}>
                            <Grid container sx={{ minHeight: '100%', flexDirection: 'column' }}>
                                {matches?(
                                <>
                                    <Grid item xs={12} sx={{margin: '30px 30px 0 30px'}}>
                                        <Title><b>Settings</b></Title>
                                    </Grid>
                                </>
                                ):(
                                <></>
                                )}
                                <Grid item xs={12}>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange} sx={{padding: matches?'10px 30px 0px 30px':'10px 10px 0px 10px'}}>
                                                <Tab sx={{textTransform: 'none', fontFamily: 'Plus Jakarta Sans', fontSize: '16px', fontWeight: 600, color: value=='1'?'#6F6AF8 !important':'#84818A', width: matches?'5%':'40%'}} label="Profile" value="1" />
                                                <Tab sx={{textTransform: 'none', fontFamily: 'Plus Jakarta Sans', fontSize: '16px', fontWeight: 600, color: value=='2'?'#6F6AF8 !important':'#84818A', width: matches?'15%':'55%'}} label="User Management" value="2" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1" sx={{padding: 0}}>
                                            <Profile />
                                        </TabPanel>
                                        <TabPanel value="2" sx={{padding: 0}}>
                                            <UserManagement />
                                        </TabPanel>
                                    </TabContext>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
};

export default Settings;

