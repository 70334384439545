import React, { FC, useContext } from "react";
import classNames from "classnames";
import Content from '../Content/Content';
import HeaderRoutes from '../Header/HeaderRoutes';
import FooterRoutes from '../Footer/FooterRoutes';


export const WrapperContainer = ({ children, className, ...props }) => {

	return (
		<div
			className={classNames(
				'wrapper',
				className,
			)}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}>
			{children}
		</div>
	);
};


const Wrapper = () => {
    return (
        <>
            <WrapperContainer>
                <HeaderRoutes />
                <Content />
                <FooterRoutes />
            </WrapperContainer>
        </>
    )
}

export default Wrapper;