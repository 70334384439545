import React, { useEffect } from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Typography, Button, Avatar, Divider, List, ListItem, ListItemText, OutlinedInput, FormControl, InputAdornment } from "@mui/material";
import DatabaseTypeIcon from '../assets/img/DatabaseType.svg';
import ServerNameIcon from '../assets/img/ServerName.svg';
import AuthenticationMethodIcon from '../assets/img/AuthenticationMethod.svg';
import UserNameIcon from '../assets/img/UserName.svg';
import PasswordIcon from '../assets/img/Password.svg';
import DisconnectIcon from '../assets/img/Disconnect.svg';
import Visibility from "@material-ui/icons/Visibility";
import useMediaQuery from '@mui/material/useMediaQuery';
import DatabaseSettings from "../components/DatabaseSettings";
import DatabaseConnection from "../components/DatabaseConnection";

const ContentTitle = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    marginLeft: '10px',
    color: '#84818A',
}))

const ContentText = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    marginLeft: '10px',
}))

const Title = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '30px',
}))

const Content = styled(Typography)(({ theme }) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    color: '#84818A',
}))

const DisconnectButton = styled(Button)(({theme}) => ({
    width:'250px', 
    height: '43px',
    textTransform: 'none', 
    border: '1px solid', 
    // borderColor: '#D6DDEB',
    
    backgroundColor: "#F02532", 
    fontFamily: 'Plus Jakarta Sans', 
    color: 'white',
    // '& :hover': {
    //     backgroundColor: 'red',
    // }
}))

const Database = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();

    const [isLogin, setIsLogin] = React.useState(false);
    const handleLogin = () => setIsLogin(true);

    return (
        <>
            <Grid container sx={{ marginTop: '10px', backgroundColor: '#F2F2F2' }}>
                <Grid item xs={12}>
                    <Box
                        sx={{
                            paddingLeft: matches?'80px':'0px',
                            // paddingRight: matches?'10px':'10px',
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                                m: 1,
                                width: '100%',
                                height: '90vh',
                            },
                        }}
                    >
                        <Paper elevation={1}>
                            {!isLogin? (
                                    <DatabaseConnection handleLogin={handleLogin} />
                                ):(
                                    <DatabaseSettings />
                                )
                            }
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
};

export default Database;

