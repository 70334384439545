import React from "react";
// import LoginBackground from '../../assets/img/Login.svg';
import LoginBackground from '../../assets/img/Login.png';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Grid, Typography, FormControl, OutlinedInput, Checkbox, Link, Button, Divider } from '@mui/material';
import { blue } from '@mui/material/colors';
// import GoogleIcon from '@mui/icons-material/Google';
import GoogleIcon from '../../assets/img/Google.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';


const BackgroundGrid = styled(Grid)(({theme}) => ({
    width:'100%', 
    height:'100vh', 
    backgroundImage: "linear-gradient( rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.6)), url(/img/Login.png)", 
    backgroundRepeat: 'no-repeat', 
    backgroundSize: '100% 100%',
}))

const BackgroundTitle = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '30px',
    color: 'white',
    textAlign: 'center'
}))

const BackgroundText = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    color: 'white',
    textAlign: 'center'
}))

const ContentGrid = styled(Grid)(({theme}) => ({
    // marginTop: '100px',
}))

const LogoGrid = styled(Grid)(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
}))

const ContentTitle = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '26px',
}))

const ContentText = styled(Typography)(({theme}) => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: '500'
}))

const RememberGrid = styled(Grid)(({theme}) => ({
    display: 'flex',
    justifyContent: 'left'
}))

const CustomButton = styled(Button)(({theme}) => ({
    width:'100%', 
    height: '43px',
    textTransform: 'none', 
    backgroundColor: "#6F6AF8", 
    fontFamily: 'Plus Jakarta Sans',
    '&:hover': {
        backgroundColor: "#6F6AF8 !important"
    }
}))

const GoogleButton = styled(Button)(({theme}) => ({
    width:'100%', 
    height: '43px',
    textTransform: 'none', 
    border: '1px solid', 
    borderColor: '#D6DDEB',
    fontFamily: 'Plus Jakarta Sans', 
    color: 'black',
}))


const Login = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    return (
        <Box>
            <Grid container>
                {matches? (
                <>
                    <BackgroundGrid item xs={5}>
                        <div style={{marginTop: '80vh'}}>
                            <BackgroundTitle>Real-Time Data Intelligence</BackgroundTitle>
                            <BackgroundText>Effortlessly obtain instant data insights, anytime, <br/>anywhere, on any device.</BackgroundText>
                        </div>
                    </BackgroundGrid>
                </>
                ):(
                <>
                </>)}
                <Grid item xs={matches? 7 : 12}>
                    <ContentGrid container spacing={2}>
                        <LogoGrid item xs={12} sx={{margin: matches?'100px 30% 10px 30%':'20px 10px 10px 10px'}}>
                            <img src="/img/Logo.png" alt="logo" width={matches?'250px':'200px'}></img>
                        </LogoGrid>
                        {matches? (
                        <>
                        </>
                        ):(
                        <>
                            <hr width='100%'/>
                        </>)}
                        <Grid item xs={12} sx={{ margin: matches?'50px 30% 10px 30%':'20px 20px 0px 20px' }}>
                            <ContentTitle><strong>Sign In To Your Account</strong></ContentTitle>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: matches?'0px 30% 10px 30%':'0px 20px 10px 20px', paddingTop:'0px !important' }}>
                            <ContentText sx={{fontWeight: '500', fontSize: '14px'}}>Don't have an account? <Link href="/signup" underline="none" sx={{color: '#6F6AF8', cursor: 'pointer'}}>Register Now!</Link></ContentText>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: matches?'0px 30% 0px 30%':'20px 20px 10px 20px'}}>
                            <ContentText>Email</ContentText>
                            <FormControl sx={{ width: '100%', marginTop: '10px' }} size="small">
                                <OutlinedInput sx={{fontFamily: 'Plus Jakarta Sans'}} placeholder="hello@email.com" />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: matches?'0px 30% 0px 30%':'0px 20px 0px 20px'}}>
                            <ContentText>Password</ContentText>
                            <FormControl sx={{ width: '100%', marginTop: '10px' }} size="small">
                                <OutlinedInput sx={{fontFamily: 'Plus Jakarta Sans'}} placeholder="Enter Password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <RememberGrid item xs={12} sx={{ margin: matches?'0px 30% 10px 30%':'0px 20px 0px 20px'}}>
                            <Checkbox
                                defaultChecked
                                sx={{
                                    padding: '0 10px 0 0',
                                    color: '#6F6AF8',
                                    '&.Mui-checked': {
                                        color: '#6F6AF8',
                                    },
                                }}
                            />
                            <ContentText>Remember Me</ContentText>
                            <Link href="/forgotpassword" underline="none" sx={{color: '#6F6AF8', cursor: 'pointer', marginLeft: 'auto', fontWeight: '700'}}>{'Forgot Password?'}</Link>
                        </RememberGrid>
                        <Grid item xs={12} sx={{ margin: matches?'0px 30% 10px 30%':'20px 20px 0px 20px'}}>
                            <CustomButton variant="contained">
                                Login
                            </CustomButton>
                        </Grid>
                        <Grid item xs={12} sx={{ margin: matches?'0px 30% 10px 30%':'0px 20px 0px 20px'}}>
                            <GoogleButton>
                                <img src={GoogleIcon} style={{marginRight: '10px'}}/>
                                <strong>Continue with Google</strong>
                            </GoogleButton>
                        </Grid>
                    </ContentGrid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Login;